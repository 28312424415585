.content-text {
  font-family: "Jost-Medium";
  font-size: 1.125rem;
  text-align: center;
  color: #1c1c1c;
  padding: 20px;
}

.divider {
  margin-right: 30px;
  margin-left: 30px;
}

.confirm-dialog-actions {
  padding: 5px 30px 20px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.confirm-dialog-actions button {
  font-size: 1.125rem;
}

.contained-dialog {
  padding: 20px 60px 0 60px;
}

.dialog-Content {
  border-bottom: 0.5px solid rgb(182, 182, 182);
  text-align: center;
 font-family: "Futura Md BT";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  padding: 0.8rem 0.4rem;
}

.contained-dialog-margin {
  margin: 20px 30px 0 30px;
  text-align: center;
}
