@media only screen and (max-width: 600px) {
  .container {
    margin-top: 5%;
    margin-left: 5%;
    width: 85%;
    height: 120px;
    background: #ffffff;
    flex: none;
    order: 1;
    flex-grow: 0;
    overflow: hidden;
  }
  .heading {
    width: 100%;
    height: 20%;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #6d6e71;
    flex: none;
    order: 0;
    flex-grow: 0;
    font-family: "Futura Md BT";
  }
  .content {
    margin-top: 4%;
    width: 100%;
    height: auto;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    flex: none;
    order: 1;
    flex-grow: 0;
    font-family: "Futura Md BT";

    color: #000000;
  }
  .midContent {
    margin-top: 10%;
  }
  .hrTag {
    margin-top: 2rem;
  }

  .version {
    padding: 0 !important;
  }
  /* text ligh  effect*/
}

@media only screen and (max-width: 1800px) and (min-width: 602px) {
  .container {
    display: flex !important;
    flex-direction: column;
    height: 20vh;
  }

  .topContent {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 20px auto;
  }
  .content {
    text-align: left;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #6d6e71;
  }
  .heading {
    width: 100%;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 600;
    font-size: 2rem;
    line-height: 2rem;
    letter-spacing: 2px;
    color: #000000;
    margin-bottom: 30px;
    text-align: left;
  }
  .midContent {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 20px auto;
  }
  li {
    color: black;
  }
  .hrTag {
    margin-top: 5rem;
  }
}

@media only screen and (min-width: 1800px) {
  .container {
    display: flex !important;
    flex-direction: column;
    height: 20vh;
  }
  .topContent {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 20px auto;
  }
  .content {
    text-align: left;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #6d6e71;
  }
  .heading {
    width: 100%;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 600;
    font-size: 3rem;
    line-height: 2rem;
    letter-spacing: 2px;
    color: #000000;
    margin-bottom: 30px;
    text-align: left;
  }
  .midContent {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 20px auto;
  }
  li {
    color: black;
  }
}

@media only screen and (min-width: 2400px) {
  .container {
    display: flex !important;
    flex-direction: column;
    height: 20vh;
  }
  .topContent {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 20px auto;
  }
  .content {
    text-align: left;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 38px;
    line-height: 50px;
    color: #6d6e71;
  }
  .heading {
    width: 100%;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 600;
    font-size: 4rem;
    line-height: 2rem;
    letter-spacing: 2px;
    color: #000000;
    margin-bottom: 30px;
    text-align: left;
  }
  .midContent {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 20px auto;
  }
  li {
    color: black;
  }
}

@media only screen and (min-width: 3000px) {
  .container {
    display: flex !important;
    flex-direction: column;
    height: 20vh;
  }
  .topContent {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 20px auto;
  }
  .content {
    text-align: left;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 2.5em;
    line-height: 3rem;
    color: #6d6e71;
    margin-top: 1rem;
  }
  .heading {
    width: 100%;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 600;
    font-size: 5rem;
    line-height: 2rem;
    letter-spacing: 2px;
    color: #000000;
    margin-bottom: 30px;
    text-align: left;
  }
  .midContent {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 20px auto;
  }
  li {
    color: black;
  }
}

@media only screen and (min-width: 3600px) {
  .container {
    display: flex !important;
    flex-direction: column;
    height: 20vh;
  }
  .topContent {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 20px auto;
  }
  .content {
    text-align: left;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 3rem;
    line-height: 4rem;
    color: #6d6e71;
    margin-top: 1rem;
  }
  .heading {
    width: 100%;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 600;
    font-size: 6rem;
    line-height: 2rem;
    letter-spacing: 2px;
    color: #000000;
    margin-bottom: 30px;
    text-align: left;
  }
  .midContent {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 20px auto;
  }
  li {
    color: black;
  }
}
/* 
@media only screen and (min-width: 4200px) {
  .container {
    display: flex !important;
    flex-direction: column;
    height: 70vh;
  }
  .topContent {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 20px auto;
  }
  .content {
    text-align: left;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 68px;
    line-height: 100px;
    color: #6d6e71;
    margin-top: 1rem;
  }
  .heading {
    width: 100%;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 600;
    font-size: 7rem;
    line-height: 2rem;
    letter-spacing: 2px;
    color: #000000;
    margin-bottom: 30px;
    text-align: left;
  }
  .midContent {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 20px auto;
  }
  li {
    color: black;
  }
}

@media only screen and (min-width: 4800px) {
  .container {
    display: flex !important;
    flex-direction: column;
    height: 70vh;
  }
  .topContent {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 20px auto;
  }
  .content {
    text-align: left;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 78px;
    line-height: 120px;
    color: #6d6e71;
    margin-top: 4rem;
  }
  .heading {
    width: 100%;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 600;
    font-size: 8rem;
    line-height: 2rem;
    letter-spacing: 2px;
    color: #000000;
    margin-bottom: 30px;
    text-align: left;
    margin-top: 4rem;
  }
  .midContent {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 20px auto;
  }
  li {
    color: black;
  }
}

@media only screen and (min-width: 5400px) {
  .container {
    display: flex !important;
    flex-direction: column;
    height: 70vh;
  }
  .topContent {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 20px auto;
  }
  .content {
    text-align: left;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 78px;
    line-height: 120px;
    color: #6d6e71;
    margin-top: 4rem;
  }
  .heading {
    width: 100%;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 600;
    font-size: 8rem;
    line-height: 2rem;
    letter-spacing: 2px;
    color: #000000;
    margin-bottom: 30px;
    text-align: left;
    margin-top: 4rem;
  }
  .midContent {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 20px auto;
  }
  li {
    color: black;
  }
}

@media only screen and (min-width: 6000px) {
  .container {
    display: flex !important;
    flex-direction: column;
    height: 70vh;
  }
  .topContent {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 20px auto;
  }
  .content {
    text-align: left;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 88px;
    line-height: 140px;
    color: #6d6e71;
    margin-top: 6rem;
  }
  .heading {
    width: 100%;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 600;
    font-size: 9rem;
    line-height: 2rem;
    letter-spacing: 2px;
    color: #000000;
    margin-bottom: 30px;
    text-align: left;
    margin-top: 6rem;
  }
  .midContent {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 20px auto;
  }
  li {
    color: black;
  }
}
@media only screen and (min-width: 6600px) {
  .container {
    display: flex !important;
    flex-direction: column;
    height: 70vh;
  }
  .topContent {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 20px auto;
  }
  .content {
    text-align: left;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 88px;
    line-height: 140px;
    color: #6d6e71;
    margin-top: 6rem;
  }
  .heading {
    width: 100%;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 600;
    font-size: 9rem;
    line-height: 2rem;
    letter-spacing: 2px;
    color: #000000;
    margin-bottom: 30px;
    text-align: left;
    margin-top: 6rem;
  }
  .midContent {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 20px auto;
  }
  li {
    color: black;
  }
}

@media only screen and (min-width: 7200px) {
  .container {
    display: flex !important;
    flex-direction: column;
    height: 70vh;
  }
  .topContent {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 20px auto;
  }
  .content {
    text-align: left;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 100px;
    line-height: 140px;
    color: #6d6e71;
    margin-top: 8rem;
  }
  .heading {
    width: 100%;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 600;
    font-size: 10rem;
    line-height: 2rem;
    letter-spacing: 2px;
    color: #000000;
    margin-bottom: 30px;
    text-align: left;
    margin-top: 8rem;
  }
  .midContent {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 20px auto;
  }
  li {
    color: black;
  }
} */
