.disclaimerTextDesk,
.disclaimerTextTab {
  color: #ffffff;
  margin-top: 1rem;
}
.disclaimerTextTab {
  display: none;
}
@media only screen and (max-width: 600px) {
  .socialMediaIcons {
    position: relative;
    bottom: 20px;
  }
  .contactUsText {
    font-size: 24px;
    position: relative;
    top: 20px;
    color: white;
    text-align: left;
    text-transform: uppercase;
    font-family: "Futura Md BT";
  }
  .addressText {
    color: white;
    text-align: left;
    position: relative;
    top: 20px;
    margin-top: 20px;
    left: -0.6rem;
    font-size: 16px;
    font-style: "Futura Md BT";
    margin-left: 2.5%;
  }
  .desktopCon {
    display: none;
  }
  .container {
    max-width: 100%;
    overflow: hidden;
    height: auto;
    background: #000000;
    padding: 40px 0px 80px 0px;
  }
  .bcColor {
    height: auto;
    flex: none;
    order: 1;
    flex-grow: 0;
    padding: 0px 10px 0px 10px;
  }
  .logo {
    position: absolute;
    /* height: 20%; */
    padding-top: 2%;
    /* text-align: center; */
    width: 90%;
  }
  .logo img {
    width: 30%;
  }
  .midContent {
    display: flex;
    margin-left: 2%;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
  }
  .footerInvest {
    padding-top: 28%;
  }
  .footerOther {
    margin-top: 20px;
  }
  .heading {
    width: 100%;
    height: 10%;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #d6d6d6;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-top: 1.5rem;
    margin-bottom: 1.2rem;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    text-transform: uppercase;
    letter-spacing: 5px;
    color: #d6d6d6;
  }
  .InvestWithUs ul li,
  .Other ul li,
  .contactUs ul li,
  .footerInvest ul li,
  .contectUsCon li {
    list-style-type: none;
    width: 100%;
    height: 10%;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-top: 14px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    /* text-align: center; */
    color: #d6d6d6;
  }

  .footerTerms {
    padding-top: 6%;
    padding-left: 25%;
  }
  .footerTerms li {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
  .footerPolicy {
    padding-top: 6%;
  }
  .footerPolicy li {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #d6d6d6;
  }
  .footerHr {
    margin-top: 15%;
  }
  .footerLastHr {
    margin-top: 15%;
  }
  .scrollUp {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 16px;
    gap: 10px;
    position: absolute;
    /* width: 70%; */
    /* height: 5%;
    left: 5%; */
    margin-top: 18%;
    /* margin-left: 30px; */
    border-top: 1px solid rgb(71, 71, 73);
    width: 100%;
  }
  .contentLi {
    margin-top: 9%;
    width: 70%;
    height: 80%;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 110%;
    letter-spacing: 1px;
    color: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
    font-family: "Futura Md BT";
    font-style: normal;
    display: flex;
    justify-content: center;

    /* Primary/2 */

    color: #ffffff;
    /* background: rgba(146, 146, 146, 0.49); */
    border-radius: 108px;
  }
  .scrollUp img {
    position: "relative" !important;
    /* top: 10px !important; */
    width: 20px;
    right: 100px !important;
  }
  .socialMediaIcons {
    margin-top: 22%;
    /* display: flex; */
    gap: 10%;
    justify-content: center;
    margin-bottom: 30px;
  }
  .copyright {
    position: absolute;
    width: 100%;
    height: 10%;
    left: 2%;
    margin-top: 5%;
    /* top: 615px; */
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #d6d6d6;
    font-family: "Futura Md BT";
  }
  .line {
    position: absolute;
    width: 2.2%;
    height: 0px;
    left: 30%;
    margin-top: 7.5%;
    border: 0.3px solid #ffffff;
    transform: rotate(90deg);
  }
  .name {
    position: absolute;
    width: 90%;
    height: 10%;
    left: 35%;
    margin-top: 5%;
    padding-right: 5%;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #d6d6d6;
    /*font-family: "Futura Md BT"; */
    font-family: "Futura Md BT";
  }

  .footerRights {
    position: absolute;
    width: 50%;
    height: 10%;
    left: 5%;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;

    color: #d6d6d6;
    font-family: "Futura Md BT";
  }
  .footerEnd {
    position: relative;
    /* right: 10px; */
    bottom: 50px;
    display: flex;

    width: 100%;
    /* justify-content: center; */
  }
  .listItem {
    list-style-type: none !important;
    margin-top: 14px !important;
  }

  .footerCorporate {
    margin-top: 0 !important;
  }
  .disclaimerTextTab {
    display: none;
  }
}
@media only screen and (max-width: 1023px) and (min-width: 601px) {
  .logo img {
    width: 75%;
    cursor: pointer;
  }
  .contactUs {
    /* background: red; */
    position: relative;
    width: 140px;
  }

  .contactDetais {
    /* position: absolute; */
    top: 50px;
    color: white;
  }
  li.landingProject {
    width: 70%;
  }
  .container {
    display: none;
  }
  .desktopCon {
    margin: 0px auto;
    background: #01010b;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    row-gap: 20px;
  }
  .firstRow {
    width: 90%;
    margin: 20px auto;
    display: flex;
    justify-content: space-between;
    position: relative;
    /* top: 100px; */
  }
  .logo {
    width: 20%;
    cursor: pointer;
    /* background-color:red ; */
  }
  .logo img {
    width: 75%;
  }
  .secondRow {
    width: 90%;
    margin: 20px auto;
    display: flex;
    position: relative;
    /* top: 200px; */
    justify-content: space-between;
  }
  .secondRowUl {
    display: flex;
    justify-content: space-around;
  }
  .secondRowUl li {
    margin-left: 10px;
  }
  .heading {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 1.7rem;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #d6d6d6;
    width: max-content;
  }
  .InvestWithUs ul li,
  .Other ul li,
  .contactUs ul li {
    /*font-family: "Futura Md BT"; */
    font-size: "Futura Md BT";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    color: #d6d6d6;
    margin-top: 14px;
    /* text-transform: uppercase; */
  }
  .socialIcons {
    width: 15%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .socialIcons img {
    margin: 15px;
    cursor: pointer;
  }
  .listItem {
    list-style-type: none !important;
    margin-top: 14px !important;
  }

  .companyFooter {
    width: 45%;
  }
  .disclaimerTextTab {
    display: block;
    width: 90%;
    margin: 20px auto;
  }
  .disclaimerTextDesk {
    display: none !important;
  }
}
@media only screen and (max-width: 1800px) and (min-width: 1024px) {
  .contactUs {
    /* background: red; */
    position: relative;
    width: 23%;
  }
  .contactDetais {
    position: absolute;
    top: 50px;
    color: white;
  }
  .socialMediaIcons img {
    margin: 10px;
    width: 100%;
    cursor: pointer !important;
  }
  .container {
    display: none;
  }
  .desktopCon {
    margin-bottom: 3rem;
    background: #01010b;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 40px 0px 80px 0px;
  }
  .firstRow {
    width: 90%;
    margin: 20px auto;
    display: flex;
    justify-content: space-between;
    position: relative;
    /* top: 100px; */
  }
  .secondRow {
    width: 90%;
    margin: 20px auto;
    display: flex;
    position: relative;
    /* top: 200px; */
    flex-direction: column;
    justify-content: space-between;
  }
  .secondRowUl {
    display: flex;
    justify-content: space-around;
  }
  .secondRowUl li {
    margin-left: 10px;
  }
  .heading {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 2rem;
    line-height: 2rem;
    letter-spacing: 2px;
    display: flex;
    align-items: center;
    color: #d6d6d6;
  }
  .InvestWithUs ul li,
  .Other ul li,
  .contactUs ul li {
    /*font-family: "Futura Md BT"; */
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 300;
    font-size: 16px !important;
    line-height: 20px;
    color: #d6d6d6;
    margin-top: 14px;
    /* text-transform: uppercase; */
  }
  .socialIcons {
    width: 10%;
    display: flex;
    justify-content: space-between;
  }
  .socialIcons img {
    margin: 5px;
    cursor: pointer;
  }
  .logo {
    width: 20%;
    cursor: pointer;
    /* background-color:red ; */
  }
  .logo img {
    width: 75%;
  }
  .listItem {
    list-style-type: none;
    margin-top: 14px !important;
  }
}

@media only screen and (min-width: 1800px) {
  .contactUs {
    /* background: red; */
    position: relative;
    width: 23%;
  }
  .contactDetais {
    position: absolute;
    top: 50px;
    color: white;
  }
  .socialMediaIcons img {
    margin: 10px;
    width: 100%;
    cursor: pointer !important;
  }
  .container {
    display: none;
  }
  .desktopCon {
    margin-bottom: 3rem;
    background: #01010b;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 40px 0px 80px 0px;
  }
  .firstRow {
    width: 90%;
    margin: 20px auto;
    display: flex;
    justify-content: space-between;
    position: relative;
    /* top: 100px; */
  }
  .secondRow {
    width: 90%;
    margin-top: 200px;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    /* top: 200px; */
    justify-content: space-between;
  }
  .secondRowUl {
    display: flex;
    justify-content: space-around;
  }
  .secondRowUl li {
    margin-left: 10px;
  }
  .heading {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 2rem;
    line-height: 2rem;
    letter-spacing: 2px;
    display: flex;
    align-items: center;
    color: #d6d6d6;
  }
  .InvestWithUs ul li,
  .Other ul li,
  .contactUs ul li {
    /*font-family: "Futura Md BT"; */
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 300;
    font-size: 16px !important;
    line-height: 20px;
    color: #d6d6d6;
    margin-top: 14px;
    /* text-transform: uppercase; */
  }
  .socialIcons {
    width: 10%;
    display: flex;
    justify-content: space-between;
  }
  .socialIcons img {
    margin: 5px;
    cursor: pointer;
  }
  .logo {
    width: 20%;
    cursor: pointer;
    /* background-color:red ; */
  }
  .logo img {
    width: 75%;
  }
  .listItem {
    list-style-type: none !important;
    margin-top: 14px !important;
  }
}

@media only screen and (min-width: 2400px) {
  .contactUs {
    /* background: red; */
    position: relative;
    width: 23%;
  }
  .contactDetais {
    position: absolute;
    top: 50px;
    color: white;
  }
  .socialMediaIcons img {
    margin: 10px;
    width: 100%;
    cursor: pointer !important;
  }
  .container {
    display: none;
  }
  .desktopCon {
    margin-bottom: 3rem;
    background: #01010b;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 40px 0px 80px 0px;
  }
  .firstRow {
    width: 90%;
    margin: 20px auto;
    display: flex;
    justify-content: space-between;
    position: relative;
    /* top: 100px; */
  }
  .secondRow {
    width: 90%;
    margin-top: 200px;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    /* top: 200px; */
    justify-content: space-between;
  }
  .secondRowUl {
    display: flex;
    justify-content: space-around;
  }
  .companyFooter {
    font-size: 20px;
  }
  .secondRowUl li {
    margin-left: 10px;
  }
  .heading {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 3rem;
    line-height: 2rem;
    letter-spacing: 2px;
    display: flex;
    align-items: center;
    color: #d6d6d6;
  }
  .InvestWithUs ul li,
  .Other ul li,
  .contactUs ul li {
    /*font-family: "Futura Md BT"; */
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 300;
    font-size: 32px !important;
    line-height: 20px;
    color: #d6d6d6;
    margin-top: 14px !important;
    /* text-transform: uppercase; */
  }
  .officeAddress {
    font-size: 24px !important;
    margin-top: 1rem;
  }
  .socialMediaHeading {
    font-size: 32px;
  }
  .socialIcons {
    width: 18% !important;
    display: flex;
    justify-content: space-between;
  }
  .socialIcons img {
    margin: 5px;
    cursor: pointer;
    width: 100px !important;
  }
  .youtubeIcon {
    cursor: pointer;
    width: 100px !important;
    height: 50px;
  }
  .logo {
    width: 20%;
    cursor: pointer;
    /* background-color:red ; */
  }
  .logo img {
    width: 75%;
  }
  .listItem {
    list-style-type: none !important;
    margin-top: 14px !important;
  }
}

@media only screen and (min-width: 3000px) {
  .contactUs {
    /* background: red; */
    position: relative;
    width: 23%;
  }
  .contactDetais {
    position: absolute;
    top: 50px;
    color: white;
  }
  .socialMediaIcons img {
    margin: 10px;
    width: 100%;
    cursor: pointer !important;
  }
  .container {
    display: none;
  }
  .desktopCon {
    margin-bottom: 3rem;
    background: #01010b;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 40px 0px 80px 0px;
  }
  .firstRow {
    width: 90%;
    margin: 20px auto;
    display: flex;
    justify-content: space-between;
    position: relative;
    /* top: 100px; */
  }
  .secondRow {
    width: 90%;
    margin-top: 200px;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    /* top: 200px; */
    justify-content: space-between;
  }
  .secondRowUl {
    display: flex;
    justify-content: space-around;
  }
  .secondRowUl li {
    margin-left: 10px;
  }
  .heading {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 4rem;
    line-height: 2rem;
    letter-spacing: 2px;
    display: flex;
    align-items: center;
    color: #d6d6d6;
  }
  .InvestWithUs ul li,
  .Other ul li,
  .contactUs ul li {
    /*font-family: "Futura Md BT"; */
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 300;
    font-size: 35px !important;
    line-height: 20px;
    color: #d6d6d6;
    margin-top: 18px !important;
    /* text-transform: uppercase; */
  }
  .officeAddress {
    font-size: 34px !important;
    margin-top: 2rem !important;
  }
  .socialMediaHeading {
    font-size: 42px;
  }
  .socialIcons {
    width: 22% !important;
    display: flex;
    justify-content: space-between;
  }
  .companyFooter {
    font-size: 23px;
  }
  .socialIcons img {
    margin: 5px;
    cursor: pointer;
    width: 90px !important;
  }
  .youtubeIcon {
    cursor: pointer;
    width: 100px !important;
    height: 60px;
    margin-top: 15px !important;
  }
  .logo {
    width: 20%;
    cursor: pointer;
    /* background-color:red ; */
  }
  .logo img {
    width: 75%;
  }
  .listItem {
    list-style-type: none !important;
    margin-top: 14px !important;
  }
}

@media only screen and (min-width: 3600px) {
  .contactUs {
    /* background: red; */
    position: relative;
    width: 23%;
  }
  .contactDetais {
    position: absolute;
    top: 50px;
    color: white;
  }
  .socialMediaIcons img {
    margin: 10px;
    width: 100%;
    cursor: pointer !important;
  }
  .container {
    display: none;
  }
  .desktopCon {
    margin-bottom: 3rem;
    background: #01010b;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 40px 0px 80px 0px;
  }
  .firstRow {
    width: 90%;
    margin: 20px auto;
    display: flex;
    justify-content: space-between;
    position: relative;
    /* top: 100px; */
  }
  .secondRow {
    width: 90%;
    margin-top: 200px;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    /* top: 200px; */
    justify-content: space-between;
  }
  .secondRowUl {
    display: flex;
    justify-content: space-around;
  }
  .secondRowUl li {
    margin-left: 10px;
  }
  .heading {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 4rem;
    line-height: 2rem;
    letter-spacing: 2px;
    display: flex;
    align-items: center;
    color: #d6d6d6;
  }
  .InvestWithUs ul li,
  .Other ul li,
  .contactUs ul li {
    /*font-family: "Futura Md BT"; */
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 300;
    font-size: 30px !important;
    line-height: 20px;
    color: #d6d6d6;
    margin-top: 14px;
    /* text-transform: uppercase; */
  }
  .officeAddress {
    font-size: 35px !important;
    margin-top: 1rem !important;
  }
  .socialMediaHeading {
    font-size: 52px;
  }
  .socialIcons {
    width: 19% !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .companyFooter {
    font-size: 25px;
  }
  .socialIcons img {
    margin: 5px;
    cursor: pointer;
    width: 90px !important;
  }
  .youtubeIcon {
    cursor: pointer;
    width: 100px !important;
    height: 60px;
    margin-top: 15px !important;
  }
  .logo {
    width: 20%;
    cursor: pointer;
    /* background-color:red ; */
  }
  .logo img {
    width: 75%;
  }
  .listItem {
    list-style-type: none !important;
    margin-top: 14px !important;
  }

  .landingProject {
    margin-top: 14px !important;
  }
}

@media only screen and (min-width: 4200px) {
  .contactUs {
    /* background: red; */
    position: relative;
    width: 23%;
  }
  .contactDetais {
    position: absolute;
    top: 50px;
    color: white;
  }
  .socialMediaIcons img {
    margin: 10px;
    width: 100%;
    cursor: pointer !important;
  }
  .container {
    display: none;
  }
  /* .desktopCon {
    margin-bottom: 3rem;
    background: #01010b;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 40px 0px 80px 0px;
  } */
  .firstRow {
    width: 90%;
    margin: 20px auto;
    display: flex;
    justify-content: space-between;
    position: relative;
    /* top: 100px; */
  }
  .secondRow {
    width: 90%;
    margin-top: 200px;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    /* top: 200px; */
    justify-content: space-between;
  }
  .secondRowUl {
    display: flex;
    justify-content: space-around;
  }
  .secondRowUl li {
    margin-left: 10px;
  }
  /* .heading {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 6rem;
    line-height: 2rem;
    letter-spacing: 2px;
    display: flex;
    align-items: center;
    color: #d6d6d6;
  } */
  /* ul li {
    font-family: "Futura Md BT";
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 300;
    font-size: 62px !important;
    line-height: 20px;
    color: #d6d6d6;
    margin-top: 90px !important;
    text-transform: uppercase;
  } */
  /* .officeAddress {
    font-size: 54px !important;
    margin-top: 4rem !important;
  } */
  .socialMediaHeading {
    font-size: 62px;
  }
  .socialIcons {
    width: 47% !important;
    display: flex;
    justify-content: space-between;
    margin-left: 1rem;
  }
  .socialIcons img {
    margin: 5px;
    cursor: pointer;
    width: 90px !important;
  }
  .youtubeIcon {
    cursor: pointer;
    width: 150px !important;
    height: 80px;
    margin-top: 15px !important;
  }
  .logo {
    width: 20%;
    cursor: pointer;
    /* background-color:red ; */
  }
  .logo img {
    width: 75%;
  }
  .listItem {
    list-style-type: none !important;
    margin-top: 14px !important;
  }
}

/* @media only screen and (min-width: 4800px) {
  .contactUs {
    background: red;
    position: relative;
    width: 23%;
  }
  .contactDetais {
    position: absolute;
    top: 50px;
    color: white;
  }
  .socialMediaIcons img {
    margin: 10px;
    width: 100%;
    cursor: pointer !important;
  }
  .container {
    display: none;
  }
  .desktopCon {
    margin-bottom: 3rem;
    background: #01010b;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 40px 0px 80px 0px;
  }
  .firstRow {
    width: 90%;
    margin: 20px auto;
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 100px;
  }
  .secondRow {
    width: 90%;
    margin: 20px auto;
    display: flex;
    position: relative;
    top: 200px;
    justify-content: space-between;
  }
  .secondRowUl {
    display: flex;
    justify-content: space-around;
  }
  .secondRowUl li {
    margin-left: 10px;
  }
  .heading {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 7rem;
    line-height: 2rem;
    letter-spacing: 2px;
    display: flex;
    align-items: center;
    color: #d6d6d6;
  }
  ul li {
    font-family: "Futura Md BT";
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 300;
    font-size: 72px !important;
    line-height: 20px;
    color: #d6d6d6;
    margin-top: 130px !important;
    text-transform: uppercase;
  }
  .officeAddress {
    font-size: 54px !important;
    margin-top: 4rem !important;
  }
  .socialMediaHeading {
    font-size: 62px;
  }
  .socialIcons {
    width: 47% !important;
    display: flex;
    justify-content: space-between;
    margin-left: 1rem;
  }
  .socialIcons img {
    margin: 5px;
    cursor: pointer;
    width: 90px !important;
  }
  .youtubeIcon {
    cursor: pointer;
    width: 150px !important;
    height: 80px;
    margin-top: 15px !important;
  }
  .logo {
    width: 20%;
    cursor: pointer;
    background-color:red ;
  }
  .logo img {
    width: 75%;
  }
  li {
    list-style-type: none !important;
    margin-top: 14px !important;
  }
} */

/* @media only screen and (min-width: 5400px) {
  .contactUs {
    background: red;
    position: relative;
    width: 23%;
  }
  .contactDetais {
    position: absolute;
    top: 50px;
    color: white;
  }
  .socialMediaIcons img {
    margin: 10px;
    width: 100%;
    cursor: pointer !important;
  }
  .container {
    display: none;
  }
  .desktopCon {
    margin-bottom: 3rem;
    background: #01010b;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 40px 0px 80px 0px;
  }
  .firstRow {
    width: 90%;
    margin: 20px auto;
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 100px;
  }
  .secondRow {
    width: 90%;
    margin: 20px auto;
    display: flex;
    position: relative;
    top: 200px;
    justify-content: space-between;
  }
  .secondRowUl {
    display: flex;
    justify-content: space-around;
  }
  .secondRowUl li {
    margin-left: 10px;
  }
  .heading {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 8rem;
    line-height: 2rem;
    letter-spacing: 2px;
    display: flex;
    align-items: center;
    color: #d6d6d6;
  }
  ul li {
    font-family: "Futura Md BT";
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 300;
    font-size: 82px !important;
    line-height: 20px;
    color: #d6d6d6;
    margin-top: 150px !important;
    text-transform: uppercase;
  }
  .officeAddress {
    font-size: 54px !important;
    margin-top: 4rem !important;
  }
  .socialMediaHeading {
    font-size: 72px;

    width: 800px;
  }
  .socialIcons {
    width: 48% !important;
    display: flex;
    justify-content: space-between;
    margin-left: 1rem;
  }
  .socialIcons img {
    margin: 5px;
    cursor: pointer;
    width: 90px !important;
  }
  .youtubeIcon {
    cursor: pointer;
    width: 150px !important;
    height: 100px;
    margin-top: 15px !important;
  }
  .logo {
    width: 20%;
    cursor: pointer;
    background-color:red ;
  }
  .logo img {
    width: 75%;
  }
  li {
    list-style-type: none !important;
    margin-top: 14px !important;
  }
} */

/* @media only screen and (min-width: 6000px) {
  .contactUs {
    background: red;
    position: relative;
    width: 23%;
  }
  .contactDetais {
    position: absolute;
    top: 50px;
    color: white;
  }
  .socialMediaIcons img {
    margin: 10px;
    width: 100%;
    cursor: pointer !important;
  }
  .container {
    display: none;
  }
  .desktopCon {
    margin-bottom: 3rem;
    background: #01010b;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 40px 0px 80px 0px;
  }
  .firstRow {
    width: 90%;
    margin: 20px auto;
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 100px;
  }
  .secondRow {
    width: 90%;
    margin: 20px auto;
    display: flex;
    position: relative;
    top: 200px;
    justify-content: space-between;
  }
  .secondRowUl {
    display: flex;
    justify-content: space-around;
  }
  .secondRowUl li {
    margin-left: 10px;
  }
  .heading {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 9rem;
    line-height: 2rem;
    letter-spacing: 2px;
    display: flex;
    align-items: center;
    color: #d6d6d6;
  }
  ul li {
    font-family: "Futura Md BT";
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 300;
    font-size: 92px !important;
    line-height: 20px;
    color: #d6d6d6;
    margin-top: 180px !important;
    text-transform: uppercase;
  }
  .officeAddress {
    font-size: 64px !important;
    margin-top: 4rem !important;
  }
  .socialMediaHeading {
    font-size: 72px;

    width: 800px;
  }
  .socialIcons {
    width: 48% !important;
    display: flex;
    justify-content: space-between;
    margin-left: 1rem;
  }
  .socialIcons img {
    margin: 5px;
    cursor: pointer;
    width: 90px !important;
  }
  .youtubeIcon {
    cursor: pointer;
    width: 150px !important;
    height: 100px;
    margin-top: 15px !important;
  }
  .logo {
    width: 20%;
    cursor: pointer;
    background-color:red ;
  }
  .logo img {
    width: 75%;
  }
  li {
    list-style-type: none !important;
    margin-top: 14px !important;
  }
} */

/* @media only screen and (min-width: 6600px) {
  .contactUs {
    background: red;
    position: relative;
    width: 23%;
  }
  .contactDetais {
    position: absolute;
    top: 50px;
    color: white;
  }
  .socialMediaIcons img {
    margin: 10px;
    width: 100%;
    cursor: pointer !important;
  }
  .container {
    display: none;
  }
  .desktopCon {
    margin-bottom: 3rem;
    background: #01010b;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 40px 0px 80px 0px;
  }
  .firstRow {
    width: 90%;
    margin: 20px auto;
    display: flex;
    justify-content: space-between;
    position: relative;
    margin: 200px !important;
  }
  .secondRow {
    width: 90%;
    margin: 20px auto;
    display: flex;
    position: relative;
    top: 200px;

    justify-content: space-between;
  }
  .secondRowUl {
    display: flex;
    justify-content: space-around;
  }
  .secondRowUl li {
    margin-left: 10px;
  }
  .heading {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 9rem;
    line-height: 2rem;
    letter-spacing: 2px;
    display: flex;
    align-items: center;
    color: #d6d6d6;
    margin-top: 1rem;
  }
  ul li {
    font-family: "Futura Md BT";
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 300;
    font-size: 92px !important;
    line-height: 20px;
    color: #d6d6d6;
    margin-top: 180px !important;
    text-transform: uppercase;
  }
  .officeAddress {
    font-size: 64px !important;
    margin-top: 4rem !important;
  }
  .socialMediaHeading {
    font-size: 72px;

    width: 800px;
  }
  .socialIcons {
    width: 48% !important;
    display: flex;
    justify-content: space-between;
    margin-left: 1rem;
  }
  .socialIcons img {
    margin: 5px;
    cursor: pointer;
    width: 90px !important;
  }
  .youtubeIcon {
    cursor: pointer;
    width: 150px !important;
    height: 100px;
    margin-top: 15px !important;
  }
  .logo {
    width: 20%;
    cursor: pointer;
    background-color:red ;
  }
  .logo img {
    width: 75%;
  }
  li {
    list-style-type: none !important;
    margin-top: 14px !important;
  }
} */

/* @media only screen and (min-width: 7200px) {
  .contactUs {
    background: red;
    position: relative;
    width: 23%;
  }
  .contactDetais {
    position: absolute;
    top: 50px;
    color: white;
  }
  .socialMediaIcons img {
    margin: 10px;
    width: 100%;
    cursor: pointer !important;
  }
  .container {
    display: none;
  }
  .desktopCon {
    margin-bottom: 3rem;
    background: #01010b;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 40px 0px 80px 0px;
  }
  .firstRow {
    margin-top: 200px !important;
    width: 90%;
    margin: 20px auto;
    display: flex;
    justify-content: space-between;
    position: relative;
  }
  .secondRow {
    width: 90%;
    margin: 20px auto;
    display: flex;
    position: relative;
    top: 200px;
    justify-content: space-between;
  }
  .secondRowUl {
    display: flex;
    justify-content: space-around;
  }
  .secondRowUl li {
    margin-left: 10px;
  }
  .heading {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 10rem;
    line-height: 2rem;
    letter-spacing: 2px;
    display: flex;
    align-items: center;
    color: #d6d6d6;
    margin-top: 1rem;
  }
  ul li {
    font-family: "Futura Md BT";
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 300;
    font-size: 102px !important;
    line-height: 20px;
    color: #d6d6d6;
    margin-top: 200px !important;
    text-transform: uppercase;
  }
  .officeAddress {
    font-size: 84px !important;
    margin-top: 6rem !important;
  }
  .socialMediaHeading {
    font-size: 92px;

    width: 800px;
  }
  .socialIcons {
    width: 48% !important;
    display: flex;
    justify-content: space-between;
    margin-left: 1rem;
  }
  .socialIcons img {
    margin: 5px;
    cursor: pointer;
    width: 90px !important;
  }
  .youtubeIcon {
    cursor: pointer;
    width: 150px !important;
    height: 100px;
    margin-top: 15px !important;
  }
  .logo {
    width: 20%;
    cursor: pointer;
    background-color:red ;
  }
  .logo img {
    width: 75%;
  }
  li {
    list-style-type: none !important;
    margin-top: 14px !important;
  }
} */

.landingProject {
  text-transform: none;
  list-style: none;
}
.socialMediaHeading,
.companyFooter {
  font-family: Futura Md BT;
}
