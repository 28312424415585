@media only screen and (max-width: 600px) {
  .promises_detail_container {
    width: 100%;
  }
  .promises_icon {
    height: 48px;
    /* background: #182428; */
    border-radius: 300px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .promises_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .promises_card_heading {
    font-family: "Futura Md BT";
    padding-left: 12%;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #bb994f;
    flex: none;
    order: 1;
    flex-grow: 0;
    text-transform: uppercase;
  }
  .promises_close_icon {
    color: #6d6e71;
  }
  .promises_header_start {
    display: flex;
    align-items: center;
  }
  .promises_description_content {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #000000;
    padding-top: 8%;
    padding-right: 3%;
  }
  .promises_list {
    padding-top: 10%;
  }
  .promises_list_li {
    display: flex;
    align-items: center;
    padding-bottom: 2%;
  }
  .promises_list_data_detail {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #000000;
    padding-left: 3%;
  }
  .promises_list_icon {
    height: 40px;
    width: 40px;
  }
  .promises_button {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 7%;
    margin-top: 15%;
  }
  .promises_button_icon_style {
    background-repeat: no-repeat;
    background-size: cover;
  }
  .promises_button_data {
    display: flex;
    margin-top: -28px;
    gap: 13px;
  }
  .promises_button_data_content {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff;
  }
  .read_link {
    margin-top: 7%;
    align-items: center;
    display: flex;
    justify-content: center;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    text-decoration-line: underline;
    color: #000000;
  }
}

@media only screen and (max-width: 1025px) and (min-width: 630px) {
  .boxStyle {
    width: 60%;
  }
  .promises_detail_container {
    width: 100%;
  }
  .promises_icon {
    height: 48px;
    /* background: #182428; */
    border-radius: 300px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .promises_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .promises_card_heading {
    font-family: "Futura Md BT";
    padding-left: 12%;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #bb994f;
    flex: none;
    order: 1;
    flex-grow: 0;
    text-transform: uppercase;
  }
  .promises_close_icon {
    color: #6d6e71;
    cursor: pointer;
  }
  .promises_header_start {
    display: flex;
    align-items: center;
  }
  .promises_description_content {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #000000;
    padding-top: 8%;
    padding-right: 3%;
  }
  .promises_list {
    padding-top: 10%;
  }
  .promises_list_li {
    display: flex;
    align-items: center;
    padding-bottom: 2%;
  }
  .promises_list_data_detail {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #000000;
    padding-left: 3%;
  }
  .promises_list_icon {
    height: 40px;
    width: 40px;
  }
  .promises_button {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 7%;
    margin-top: 15%;
  }
  .promises_button_icon_style {
    background-repeat: no-repeat;
    background-size: cover;
  }
  .promises_button_data {
    display: flex;
    margin-top: -28px;
    gap: 13px;
  }
  .promises_button_data_content {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff;
    cursor: pointer;
  }
  .read_link {
    margin-top: 7%;
    align-items: center;
    display: flex;
    justify-content: center;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    text-decoration-line: underline;
    color: #000000;
  }
}

@media only screen and (min-width: 1026px) {
  .boxStyle {
    width: 40%;
  }
  .promises_detail_container {
    width: 100%;
  }
  .promises_icon {
    height: 48px;
    /* background: #182428; */
    border-radius: 300px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .promises_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .promises_card_heading {
    font-family: "Futura Md BT";
    padding-left: 12%;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #bb994f;
    flex: none;
    order: 1;
    flex-grow: 0;
    text-transform: uppercase;
  }
  .promises_close_icon {
    color: #6d6e71;
    cursor: pointer;
  }
  .promises_header_start {
    display: flex;
    align-items: center;
  }
  .promises_description_content {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #000000;
    padding-top: 8%;
    padding-right: 3%;
  }
  .promises_list {
    padding-top: 10%;
  }
  .promises_list_li {
    display: flex;
    align-items: center;
    padding-bottom: 2%;
  }
  .promises_list_data_detail {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #000000;
    padding-left: 3%;
  }
  .promises_list_icon {
    height: 40px;
    width: 40px;
  }
  .promises_button {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 7%;
    margin-top: 15%;
  }
  .promises_button_icon_style {
    background-repeat: no-repeat;
    background-size: cover;
  }
  .promises_button_data {
    display: flex;
    margin-top: -28px;
    gap: 13px;
  }
  .promises_button_data_content {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff;
    cursor: pointer;
  }
  .read_link {
    margin-top: 7%;
    align-items: center;
    display: flex;
    justify-content: center;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    text-decoration-line: underline;
    color: #000000;
  }
}

@media only screen and (min-width: 1800px){
  .promises_description_content {
    font-size: 1.5em;
    padding-top: 2rem;
  }

  .promises_list {
    padding-top: 3rem;
  }

  .promises_list_li {
    padding-bottom: .8rem;
  }

  .promises_list_data_detail {
    font-size: 1.3em;
    padding-left: .8rem;
  }
}
@media only screen and (min-width: 2400px) {
  
}
@media only screen and (min-width: 3000px) {

  .promises_icon {
    height: 150px;
  }

  .promises_card_heading {
    font-size: 4em;
  }

  .promises_description_content {
    font-size: 2.5em;
    padding-left: 2rem;
  }

  .promises_list_data_detail {
    font-size: 2.3em;
  }

  .promises_button {
    height: 100px;
    margin-top: 4rem;
  }

  .promises_button_data {
    margin-top: -3.5rem;
  }

  .promises_button_icon_style {
    height: 70px;
  }

  .promises_button_data_content {
    font-size: 1.5em;
  }
}

@media only screen and (min-width: 4000px) {
  .boxStyle {
    width: 30%;
  }
}