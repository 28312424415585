@media only screen and (max-width: 1025px) and (min-width: 625px) {
  .container {
    background: #182329;
    padding-bottom: 2rem;
  }
  .logoWithHeader {
    width: 100%;
    /* margin: 20px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .logoWithHeader img {
    /* position: relative;
        top: -10px; */
    margin-top: 50px;
  }

  .title {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 4px;
    text-transform: uppercase;
    color: #ffffff;
    position: relative;
    top: 20px;
  }

  .wrapper {
    display: flex;
    justify-content: center;
    /* background: #182329; */
    flex-wrap: wrap;
    margin-top: 30px;
  }

  .beforeAfter {
    /* max-width: 420px; */
    width: 35%;
    /* min-height: 170px; */
    height: 170px;
    background: #182428 !important;
    position: relative;
    margin: 0 auto;
    transition: all 2s ease;
    padding: 5px;
    margin-top: 10px;
    margin: 10px;
    box-sizing: border-box;
  }

  .beforeAfter:before {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;

    top: 0;
    left: 0;
    background: #182428;
    border-radius: 0 0 100% 0;
    transition: all 2s ease;
  }

  .beforeAfter::after {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;

    top: 0;
    right: 0;
    background: #182428;
    border-radius: 0 0 0 100%;
    transition: all 2s ease;
  }

  .inside :before {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;

    bottom: 0;
    left: 0;
    background: #182428;
    border-radius: 0 100% 0 0;
    transition: all 2s ease;
  }

  .inside :after {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;

    bottom: 0;
    right: 0;
    background: #182428;
    border-radius: 100% 0 0 0;
    transition: all 2s ease;
  }

  .beforeAfter .menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 2s ease;
  }

  .shieldWithTitle {
    margin-top: 20px;
    transition: all 1s ease;
    margin-left: 0px;
    transition: all 2s ease;
  }

  .shieldWithTitle img {
    width: 80px;
    margin-left: 23px;

    transition: all 1s ease;
    transition: all 2s ease;
  }

  .shieldWithTitle p {
    /* font-family: "Futura Md BT"; */
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: rgba(187, 153, 79, 1);
    text-align: center;
    letter-spacing: 4px;
    text-transform: uppercase;
    transition: all 1s ease;
    margin-top: -70px;

    font-style: normal;
    font-weight: 400;
    font-family: "Futura Md BT";
    line-height: 24px;
    /* identical to box height, or 133% */

    text-align: center;
    letter-spacing: 4px;
    text-transform: uppercase;
  }

  .arrowWrapper {
    margin-top: 0px;
    margin-right: -2px;
    transition: all 2s ease;
    cursor: pointer;
    display: none;
  }

  .arrowWrapper img {
    margin-top: 60px;
    transition: all 1s ease;
  }

  .beforeAfter .descp {
    display: none;
    transition: all 2s ease;
  }

  .openMenu {
    /* max-width: 420px; */
    min-height: 150px;
    width: 30%;

    background: rgba(255, 255, 255, 0.1);
    position: relative;
    margin: 0 auto;
    transition: all 2s ease;
    padding: 5px;
    margin-top: 20px;
    box-sizing: border-box;
    margin: 25px;
    cursor: pointer;
  }

  .openMenu:before {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;

    top: 0;
    left: 0;
    background: #182428;
    border-radius: 0 0 100% 0;
    transition: all 2s ease;
  }

  .openMenu::after {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;

    top: 0;
    right: 0;
    background: #182428;
    border-radius: 0 0 0 100%;
    transition: all 2s ease;
  }

  .openMenu .inside :before {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;

    bottom: 0;
    left: 0;
    background: #182428;
    border-radius: 0 100% 0 0;
    transition: all 2s ease;
  }

  .openMenu .inside :after {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;

    bottom: 0;
    right: 0;
    background: #182428;
    border-radius: 100% 0 0 0;
    transition: all 2s ease;
  }

  .openMenu .menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 2s ease;
  }

  .openMenu .inside .menu .shieldWithTitle {
    margin-top: 0px;
    transition: all 2s ease;
  }

  .openMenu .inside .menu .shieldWithTitle img {
    width: 40px;
    position: relative;
    top: 10px;
    transition: all 2s ease;
  }

  .openMenu .inside .menu .shieldWithTitle p {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(187, 153, 79, 1);
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-top: 10px;
    transition: all 2s ease;
  }

  .openMenu .arrowWrapper img {
    transform: translateY(00px) rotate(180deg);
    /* margin-bottom: 20px; */
    transition: all 2s ease;
  }

  .openMenu .descp {
    margin-bottom: 40px;
    color: white;
    margin-top: 30px;
    text-align: center;
    width: 90%;
    transition: all 2s ease;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    text-align: center;
    color: #ffffff;
  }

  .adjust {
    color: red !important;
  }

  .shieldWithTitle #shield2 {
    position: relative !important;
    left: 100px !important;
  }
}

@media only screen and (min-width: 1025px) {
  .container {
    background: #182329;
    padding-bottom: 3rem;
  }
  .logoWithHeader {
    width: 100%;
    /* margin: 20px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .logoWithHeader img {
    /* position: relative;
        top: -10px; */
    margin-top: 50px;
  }

  .title {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 4px;
    text-transform: uppercase;
    color: #ffffff;
    position: relative;
    top: 20px;
  }

  .wrapper {
    display: flex;
    justify-content: center;
    /* background: #182329; */
    flex-wrap: wrap;
    margin-top: 30px;
  }

  .beforeAfter {
    /* max-width: 420px; */
    width: 35%;
    /* min-height: 170px; */
    height: 170px;
    background: #182428 !important;
    position: relative;
    margin: 0 auto;
    transition: all 2s ease;
    padding: 5px;
    margin-top: 10px;
    margin: 10px;
    box-sizing: border-box;
  }

  .beforeAfter:before {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;

    top: 0;
    left: 0;
    background: #182428;
    border-radius: 0 0 100% 0;
    transition: all 2s ease;
  }

  .beforeAfter::after {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;

    top: 0;
    right: 0;
    background: #182428;
    border-radius: 0 0 0 100%;
    transition: all 2s ease;
  }

  .inside :before {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;

    bottom: 0;
    left: 0;
    background: #182428;
    border-radius: 0 100% 0 0;
    transition: all 2s ease;
  }

  .inside :after {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;

    bottom: 0;
    right: 0;
    background: #182428;
    border-radius: 100% 0 0 0;
    transition: all 2s ease;
  }

  .beforeAfter .menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 2s ease;
  }

  .shieldWithTitle {
    margin-top: 20px;
    transition: all 1s ease;
    margin-left: 0px;
    transition: all 2s ease;
  }

  .shieldWithTitle img {
    width: 80px;

    transition: all 1s ease;
    transition: all 2s ease;
  }

  .shieldWithTitle p {
    /* font-family: "Futura Md BT"; */
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: rgba(187, 153, 79, 1);
    text-align: center;
    letter-spacing: 4px;
    text-transform: uppercase;
    transition: all 1s ease;
    margin-top: -70px;

    font-style: normal;
    font-weight: 400;
    font-family: "Futura Md BT";
    line-height: 24px;
    /* identical to box height, or 133% */

    text-align: center;
    letter-spacing: 4px;
    text-transform: uppercase;
  }

  .arrowWrapper {
    margin-top: 0px;
    margin-right: -2px;
    transition: all 2s ease;
    cursor: pointer;
    display: none;
  }

  .arrowWrapper img {
    margin-top: 60px;
    transition: all 1s ease;
  }

  .beforeAfter .descp {
    display: none;
    transition: all 2s ease;
  }

  .openMenu {
    /* max-width: 420px; */
    min-height: 150px;
    width: 30%;

    background: rgba(255, 255, 255, 0.1);
    position: relative;
    margin: 0 auto;
    transition: all 2s ease;
    padding: 5px;
    margin-top: 20px;
    box-sizing: border-box;
    margin: 25px;
    cursor: pointer;
  }

  .openMenu:before {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;

    top: 0;
    left: 0;
    background: #182428;
    border-radius: 0 0 100% 0;
    transition: all 2s ease;
  }

  .openMenu::after {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;

    top: 0;
    right: 0;
    background: #182428;
    border-radius: 0 0 0 100%;
    transition: all 2s ease;
  }

  .openMenu .inside :before {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;

    bottom: 0;
    left: 0;
    background: #182428;
    border-radius: 0 100% 0 0;
    transition: all 2s ease;
  }

  .openMenu .inside :after {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;

    bottom: 0;
    right: 0;
    background: #182428;
    border-radius: 100% 0 0 0;
    transition: all 2s ease;
  }

  .openMenu .menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 2s ease;
  }

  .openMenu .inside .menu .shieldWithTitle {
    margin-top: 0px;
    transition: all 2s ease;
  }

  .openMenu .inside .menu .shieldWithTitle img {
    width: 100px;
    position: relative;
    top: 10px;
    transition: all 2s ease;
  }

  .openMenu .inside .menu .shieldWithTitle p {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    /* line-height: 24px; */
    color: rgba(187, 153, 79, 1);
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-top: -11px;
    transition: all 2s ease;
  }

  .openMenu .arrowWrapper img {
    transform: translateY(00px) rotate(180deg);
    /* margin-bottom: 20px; */
    transition: all 2s ease;
  }

  .openMenu .descp {
    margin-bottom: 20px;
    color: white;
    margin-top: 20px;
    text-align: center;
    width: 90%;
    transition: all 2s ease;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: #ffffff;
  }

  .adjust {
    color: red !important;
  }

  .shieldWithTitle #shield2 {
    position: relative !important;
    left: 100px !important;
  }
}
