.toast_error_message {
  background: red;
  color: #fff;
}

.closeDropdownWrapper,
.close-dropdown-wrapper {
  position: absolute;
  bottom: 40%;
  right: 30px;
  width: 24px;
  height: auto;
  transform: translateY(50%);
  z-index: 12;
}
.mobDropdownIcon {
  position: absolute;
  bottom: 12px;
  right: 0px;
  width: 24px;
  height: auto;
  transform: translateY(50%);
  z-index: 1;
}
.mobCloseDropdownIcon {
  position: absolute;
  border-radius: 4px;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  height: auto;
  padding: 14px 0px;
  z-index: 1;
  /* max-height: 13rem; */
}
.dropdownPhoneNumber {
  margin: -243px 0 10px -1px;
  background-color: black;
  color: #ffff;
}
.footerEnquiry{
  margin: 0 !important;
  bottom: 6vh  !important;
 overflow-y: hidden !important;    /*removed scroll as we restricted dropdown to two country */
}
.enquery{
  overflow-y: hidden !important;    /*removed scroll as we restricted dropdown to two country */
}
.dropdownIconSVG {
  width: 100%;
  height: auto;
  transition: 0.2s;
  cursor: pointer;
}

.dropdownWrapper {
  position: absolute;
  bottom: 100%;
  border-radius: 4px;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: white;
  height: auto;
  max-height: 480px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 14px 0px;
  z-index: 1;
}

.dropdownDownIcon {
  position: absolute;
  bottom: 40%;
  right: 0px;
  width: 24px;
  height: auto;
  transform: translateY(50%);
  z-index: 12;
}

.formContainer {
  display: flex;
  justify-content: center;
  align-items: end;
  flex: 1;
  display: block;
  height: auto;
}
/* for footer form */
.otpResendButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 5px 24px; */
  gap: 10px;
  width: 160px;
  height: 30px;
  background: #000000 !important;
  border-radius: 88px;
  font-family: "Futura Md BT";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #ffffff !important;
  margin-top: 2rem;
}

/* CSS FOR CUSTOM DROPDOWN */
.dropdownListText {
  font: inherit;
  padding: 4px 0px 4px 4px;
  cursor: pointer;
  color: #000000;
  margin-left: 8px;
  border-radius: 4px;
}
.dropdownListTextActive {
  background-color: #046af9 !important;
  color: #fff !important;
}
.dropdownListText:hover {
  background-color: #337ae0;
  color: #fff;
}
.mobile_input_style,.border_input_style{
  position: relative;
}
.checkbox_container {
  margin-top: 10px;
  display: flex; 
  align-items: flex-start; 
  gap: 10px; 
  width: 100%;
}

.checkbox_input {
  margin-top: 4px;
  flex-shrink: 0; 
}

.checkbox_label {
  font-size: 16px;
  line-height: 1.4;
  font-family: "Futura Md BT";
  font-weight: 500;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

@media (max-width: 480px) {
  .checkbox_label {
    font-size: 13px;
    text-align: justify;
    line-height: 1.5;
  }
  .errorMsg_text {
    font-size: 11px;
    margin-top: 2px;
  }
}
@media only screen and (min-width: 481px) {
  .errorMsg_text {
    font-size: 16px !important;
    margin-top: 2px;
  }
}
@media only screen and (max-width: 376px) and (min-width: 362px) {
  .mobileFormModalStylee {
    height: 73vh;
    position: relative;
  }
  .innerContent {
    border: 1px solid black;
    border-top: none;
    width: 100%;
    height: 100% !important;
    position: relative;
  }

  .Loader {
    width: 56px;
    height: 56px;
  }

  .mobileform {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin-top: 3rem; */
    position: relative;
    bottom: 2rem;
  }
  .mobileFormInput {
    width: 100%;
    /* margin-top: 2rem !important; */
    border: none;
    /* border-bottom: 1px solid gray; */
    background-color: transparent;
    outline: none;
    border: none;
    box-shadow: none;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    text-align: left;
    color: #000000;
  }
  .checkbox_label {
    font-size: 13px;
    line-height: 1.1;
  }
  .mobileFormModalStyleeAyodhya .mobileSubmitBtn {
    position: relative;
    /* bottom: 4rem !important; */
    margin-bottom: 1.5rem !important;
    background: black !important;
    color: white !important;
  }
  .mobileSubmitBtn:hover {
    background: black;
    color: white;
  }
  .mobileFormModalStyleeAyodhya {
    border: none;
    outline: none;
    width: 90%;
    height: 60v2 !important;
    background: rgba(255, 255, 255, 0.9);
    z-index: 999999999;
    position: relative;
    top: 18rem !important;
  }
  .CountryAndCity {
    display: flex;
    flex-direction: row;
    position: relative;
    top: 0.5rem;
  }
  .roleInputStyle {
    margin: 0px;
  }
  .cityStyle {
    width: 120px !important;
  }
  .countryStyle {
    width: 120px !important;
  }
  .mobileFormModalStyleeAyodhya .mobileSubmitBtn {
    position: relative;
    /* top: -2rem; */
  }
  .roleErrorMsg {
    color: red;
    position: relative;

    top: -0.3rem !important;
  }
}

@media only screen and (max-width: 361px) and (min-width: 330px) {
  .mobileFormModalStyleeAyodhya {
    position: relative;
    top: 22rem !important;
    border: none;

    outline: none;
    width: 90%;
    height: 65vh !important;
    background: rgba(255, 255, 255, 0.9);
    z-index: 999999;
  }
  .mobileSubmitBtn {
    position: relative;
    /* bottom: 1rem !important; */
    margin-top: 1rem !important;
  }
  .innerContent {
    border: 1px solid black;
    border-top: none;
    width: 100%;
    height: 105% !important;
    position: relative;
  }
  .mobileFormModalStyleeAyodhya .mobileform {
    position: relative;
    bottom: 1rem;
  }
  .countryStyle {
    width: 200px !important;
    position: relative;
    left: 0.4rem !important;
  }
  .cityStyle {
    width: 200px !important;
    position: relative !important;
    right: 0.3rem !important;
  }
}

@media only screen and (max-width: 625px) {
  .navigateBack {
    cursor: pointer;
  }
  .modalStyle {
    width: 90% !important;
  }
  .cotentBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .heading {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 54px;
    color: #000000;
  }

  .desc {
    text-align: center;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 140%;
    color: #727272;
    margin: 20px;
  }

  .okayBtn {
    width: 252.68px;
    height: 64px;
    background: #000000;
    border-radius: 158.829px;
    color: white;
    cursor: pointer;
    font-size: 20px;
  }

  .enqueryCon {
    display: none;
  }

  .mobileView {
    width: 100%;
    position: fixed;
    bottom: 0px;
    left: 0;
    background-color: white;
    z-index: 9999999;
  }

  .callWithEnqueryForm {
    width: 100%;
    position: relative;
    bottom: 10px;
    padding-top: 20px;
    background-color: white;
    height: 100%;
    display: flex;
    justify-content: space-around;
  }

  .callNowBtn {
    background: #000000;
    border-radius: 60px;
    width: 164px;
    height: 40px;
    left: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    color: white;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 110%;
    outline: none;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff;
    outline: none !important;
    border: none !important;
  }

  .enQueryBtn {
    border-radius: 60px;
    background-color: transparent;
    width: 164px;
    height: 40px;
    left: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 110%;
    outline: none;
    border: none;
    border: 2px solid gray;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #000000;
  }

  .mobileform {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .mobileFormInput {
    width: 100%;
    margin-top: 1rem;
    border: none;
    background-color: transparent;
    outline: none;
    border: none;
    box-shadow: none;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    text-align: left;
    color: #000000;
    padding: 0px !important;
  }

  .mobileFormInput:hover,
  .mobileFormInput:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
  }
  .phoneInput {
    margin-top: 1rem;
  }
  .mobileFormDropDown {
    border: none;
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
    overflow: none;
    width: 80%;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #000000;
  }

  .mobile_input_style {
    border-bottom: 1px solid gray;
    width: 100%;
  }

  .mobileSubmitBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 24px;
    gap: 10px;
    width: 296px;
    height: 40px;
    background: #000000 !important;
    border-radius: 88px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 110%;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff !important;
    margin-top: 1.5rem;
  }
  .mobileSubmitBtn:hover {
    background: black !important;
    color: white !important;
  }

  .navigateBack {
    display: flex;
    position: relative;
    right: 5px;
  }

  .backText {
    position: relative;
    top: 0.2rem;
    left: 10px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 24px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #000000;
  }

  .phone_number_style {
    text-decoration: none !important;
    color: #ffffff;
  }
  .mobileFormModalStylee {
    border: none;
    outline: none;
    width: 90%;

    background: rgba(255, 255, 255, 0.9);
    z-index: 999999 !important;
  }
  .mobileform {
    padding: 30px;
    margin-top: 0px !important;
  }
  .innerContent {
    border: 1px solid black;
    border-top: none;
    width: 100%;
    position: relative;
  }
  .closeBtn {
    z-index: 99999;
    background: black;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -18px;
    right: -10px;
    cursor: pointer;
  }
  .navigateBack {
    display: flex;
    justify-content: center;
    position: relative;
    bottom: 15px;
  }

  .mobileSubmitBtn {
    width: 100% !important;
    background: black !important;
    color: white !important;
    border: none;
    outline: none;
    cursor: pointer;
  }

  .forBorderLeft {
    border-top: 1px solid black;
    width: 10%;
  }
  .forBorderRight {
    border-top: 1px solid black;
    width: 10%;
    position: absolute;
    right: 0px;
  }
  .roleStyle {
    display: flex;
    flex-wrap: wrap;
  }
  .roleInputDiv {
    width: 95%;
    position: relative;
    margin: 10px 0px;
    display: flex !important;
  }
  .roleInputDiv span {
    position: relative;
    top: 5px;
    left: 2.5rem;
  }
  .roleInputStyle {
    padding: 0px !important;
    width: 10% !important;

    position: absolute;
  }
  .roleHeading {
    position: relative;
    right: 120px;
    margin-top: 10px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    color: #000000;
  }
  .mobileFormModalStyleeAyodhya {
    border: none;
    outline: none;
    width: 90%;
    height: 65vh;
    background: rgba(255, 255, 255, 0.9);
    z-index: 999999;
    position: relative;
  }
  .cityErrorMsg {
    color: red;
    position: relative;
    right: 3rem;
    top: 0.5rem;
  }
  .roleErrorMsg {
    color: red;
    position: relative;
    left: 0.5rem;
    top: 0.5rem;
  }
  .mobileFormModalStyleeAyodhya .mobileSubmitBtn {
    position: relative;
    bottom: 2rem;
  }
  .countryStyle {
    width: 250px;
    position: relative;
    left: 0.1rem;
  }
  .cityStyle {
    width: 250px;
    position: relative;
    right: 0.3rem;
  }
}

@media only screen and (max-width: 1025px) and (min-width: 626px) {
  .mobileView {
    /* display: none; */
  }
  .checkbox_label {
    font-size: 12px;
    line-height: 1.2;
  }
  .Loader {
    width: 56px;
    height: 56px;
  }
  .footerEnquiry {
    bottom: 45px !important;
  }
  .enqueryCon {
    position: fixed;

    bottom: 0px;
    width: 100%;
    height: 8vh;
    left: 0;
    background-color: black;
    z-index: 1000;
  }

  .inputStyle {
    width: 100px !important;
    padding: 10px;
    /* border-bottom: 1px solid white; */
    background-color: black !important;
    border: none !important;
    /* border-bottom: 1px solid gray !important; */
    outline: none;
    color: white;
    font-size: 15px !important;
  }

  .inputStyle::placeholder {
    font-size: 15px;
  }

  .dropDownStyle {
    background-color: black;
    border: none !important;
    border-bottom: 1px solid gray !important;
    outline: none;
    color: white;
    padding: 10px;
    width: 160px;
  }

  .enqFormStyle {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .submitBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 18.0488px 43.3171px;
    gap: 18.05px;
    width: 14%;
    height: 48px;
    background: #bfbfbf;
    border-radius: 158.829px;
    cursor: pointer;
    outline: none !important;
    border: none !important;
  }

  .cotentBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .heading {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 54px;
    /* identical to box height, or 135% */

    color: #000000;
  }

  .desc {
    text-align: center;
    /*font-family: "Futura Md BT"; */
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: #727272;
    margin: 20px;
  }

  .okayBtn {
    width: 252.68px;
    height: 64px;
    background: #000000;
    border-radius: 158.829px;
    color: white;
    cursor: pointer;
    font-size: 20px;
  }
  .mobileView {
    width: 100%;
    position: fixed;
    bottom: 0px;
    left: 0;
    background-color: white;
    z-index: 9999999;
  }
  .mobileform {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
  }
  .mobile_input_style {
    border-bottom: 1px solid gray;
    width: 100%;
  }
  .mobileFormDropDown {
    border: none;
    /* border-bottom: 1px solid gray; */
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
    overflow: none;
    width: 80%;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #000000;
  }

  .mobileFormInput {
    width: 100%;
    margin-top: 3rem;
    border: none;
    /* border-bottom: 1px solid gray; */
    background-color: transparent;
    outline: none;
    border: none;
    box-shadow: none;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    text-align: left;
    color: #000000;
  }

  .mobileFormInput:hover,
  .mobileFormInput:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
  }

  .mobileFormInput::placeholder {
    position: absolute;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    color: #000000;
  }
  .phoneInput {
    margin-top: 3rem;
  }

  .callWithEnqueryForm {
    display: none;
  }
  .mobileSubmitBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    gap: 10px;
    width: 296px;
    height: 40px;
    background: #000000;
    border-radius: 88px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 110%;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff;
    margin-top: 2rem;
  }
  .navigateBack {
    display: flex;
  }
  .backText {
    position: relative;
    top: 3px;
    left: 10px;
  }
  .mobileFormModalStylee {
    border: none;
    outline: none;
    width: 50%;
    background: rgba(255, 255, 255, 0.9);
    z-index: 999999 !important;
  }
  .mobileform {
    padding: 30px;
    margin-top: 0px !important;
  }
  .innerContent {
    border: 1px solid black;
    border-top: none;
    width: 100%;
    position: relative;
  }
  .closeBtn {
    z-index: 99999;
    background: black;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -18px;
    right: -10px;
    cursor: pointer;
  }
  .navigateBack {
    display: flex;
    justify-content: center;
    position: relative;
    bottom: 15px;
  }

  .mobileSubmitBtn {
    width: 100% !important;
    background: black !important;
    color: white !important;
    border: none;
    outline: none;
    cursor: pointer;
  }

  .forBorderLeft {
    border-top: 1px solid black;
    width: 33%;
  }
  .forBorderRight {
    border-top: 1px solid black;
    width: 30%;
    position: absolute;
    right: 0px;
  }
  .roleStyle {
    display: flex;
    flex-wrap: wrap !important;
  }
  .roleInputDiv {
    width: 40%;
    position: relative;
    margin: 10px;
    display: flex !important;
    flex-wrap: wrap;
  }
  .roleInputDiv span {
    position: relative;
    top: 5px;
    left: 2.5rem;
  }
  .roleInputStyle {
    padding: 0px !important;
    width: 8% !important;
    cursor: pointer;
    position: absolute;
  }
  .roleHeading {
    position: relative;
    right: 180px;
    margin-top: 10px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    color: #000000;
  }
  .mobileFormModalStyleeAyodhya {
    border: none;
    outline: none;
    width: 50%;
    height: 100vh;
    background: rgba(255, 255, 255, 0.9);
    z-index: 999999;
  }
  .CountryAndCity {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 1800px) and (min-width: 1030px) {
  .mobileFormModalStylee {
    border: none;
    outline: none;
    width: 50%;

    background: rgba(255, 255, 255, 0.9);
    z-index: 999999;
  }
  .mobileFormModalStyleeAyodhya {
    border: none;
    outline: none;
    width: 50%;
    height: 92vh;
    background: rgba(255, 255, 255, 0.9);
    z-index: 999999;
  }

  .mobileform {
    padding: 30px;
    margin-top: 0px !important;
  }
  .innerContent {
    border: 1px solid black;
    border-top: none;
    width: 100%;
    position: relative;
  }
  .Loader {
    width: 56px;
    height: 56px;
  }
  .closeBtn {
    z-index: 99999;
    background: black;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -18px;
    right: -10px;
    cursor: pointer;
  }
  .navigateBack {
    display: flex;
    justify-content: center;
    position: relative;
    bottom: 15px;
  }

  .mobileSubmitBtn {
    width: 100% !important;
    background: black !important;
    color: white !important;
    border: none;
    outline: none;
    cursor: pointer;
  }

  .forBorderLeft {
    border-top: 1px solid black;
    width: 33%;
  }
  .forBorderRight {
    border-top: 1px solid black;
    width: 30%;
    position: absolute;
    right: 0px;
  }
  .mobileView {
    width: 100%;
    position: fixed;
    bottom: 0px;
    left: 0;
    background-color: white;
    z-index: 9999999;
  }
  .mobileform {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
  }
  .mobile_input_style {
    border-bottom: 1px solid gray;
    width: 100%;
  }
  .mobileFormDropDown {
    border: none;
    /* border-bottom: 1px solid gray; */
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
    overflow: none;
    width: 80%;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #000000;
  }

  .mobileFormInput {
    width: 100%;
    margin-top: 1.5rem;
    border: none;
    background-color: transparent;
    outline: none;
    border: none;
    box-shadow: none;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    text-align: left;
    color: #000000;
  }

  .mobileFormInput:hover,
  .mobileFormInput:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
  }
  .phoneInput {
    margin-top: 1rem;
  }
  .mobileFormInput::placeholder {
    position: absolute;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    color: #000000;
  }
  /* .phoneInputFont {
    margin-bottom: 10px;
  } */
  .callWithEnqueryForm {
    display: none;
  }

  /* end of modal style */
  .enqueryCon {
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: 10%;
    left: 0;
    background-color: black;
    z-index: 1000;
  }

  .inputStyle {
    width: 215px !important;
    padding: 10px;
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    color: white;
    font-size: 15px !important;
    text-align: left !important;
  }

  .border_input_style {
    border-bottom: 1px solid gray !important;
  }

  .inputStyle:hover,
  .inputStyle:focus {
    background-color: transparent !important;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  .inputStyle::placeholder {
    font-size: 15px;
    background-color: transparent !important;
  }

  .dropDownStyle {
    background-color: black;
    border: none !important;
    border-bottom: 1px solid gray !important;
    outline: none;
    color: white;
    padding: 10px;
    width: 230px !important;
    margin-bottom: 3px;
  }

  .enqFormStyle {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .submitBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 18.0488px 30.3171px;
    gap: 18.05px;
    width: 152.68px;
    height: 40px;
    background: #bfbfbf;
    border-radius: 158.829px;
    cursor: pointer;
    outline: none !important;
    border: none !important;
  }

  .cotentBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .heading {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 54px;
    /* identical to box height, or 135% */

    color: #000000;
  }

  .desc {
    text-align: center;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #727272;
    margin: 20px;
  }

  .okayBtn {
    width: 252.68px;
    height: 64px;
    background: #000000;
    border-radius: 158.829px;
    color: white;
    cursor: pointer;
    font-size: 20px;
  }
  .mobileSubmitBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    gap: 10px;
    width: 296px;
    height: 40px;
    background: #000000 !important;
    border-radius: 88px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 110%;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff !important;
    margin-top: 2rem;
  }
  /* .mobileSubmitBtn:hover {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    gap: 10px;
    width: 296px;
    height: 40px;
    background: #000000 !important;
    border-radius: 88px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 110%;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff !important;
    margin-top: 2rem;
  } */
  .navigateBack {
    display: flex;
  }
  .backText {
    position: relative;
    top: 3px;
    left: 10px;
  }
  .roleStyle {
    display: flex;
    flex-wrap: wrap;
  }
  .roleInputDiv {
    width: 45%;
    position: relative;
    margin: 10px;
    display: flex !important;
  }
  .roleInputDiv span {
    position: relative;
    top: 5px;
    left: 2.5rem;
  }
  .roleInputStyle {
    padding: 0px !important;
    width: 8% !important;
    cursor: pointer;
    position: absolute;
  }
  .roleHeading {
    position: relative;
    right: 260px;
    margin-top: 10px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    color: #000000;
  }
  .CountryAndCity {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 1rem;
  }
  .cityErrorMsg {
    color: red;
    position: relative;
    left: 6.5rem;
    top: 0.5rem;
  }
  .roleErrorMsg {
    color: red;
    position: relative;
    left: 0.5rem;
    top: 0.5rem;
  }
}

@media only screen and (min-width: 1800px) {
  .mobileFormModalStylee {
    border: none;
    outline: none;
    width: 50%;

    background: rgba(255, 255, 255, 0.9);
    z-index: 99999999;
  }
  .mobileFormModalStyleeAyodhya {
    border: none;
    outline: none;
    width: 50%;
    height: 62vh;
    background: rgba(255, 255, 255, 0.9);
    z-index: 99999999;
    margin-top: 2rem;
  }

  .mobileform {
    padding: 30px;
    margin-top: 0px !important;
  }
  .innerContent {
    border: 1px solid black;
    border-top: none;
    width: 100%;
    position: relative;
  }
  .closeBtn {
    z-index: 99999;
    background: black;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -18px;
    right: -10px;
    cursor: pointer;
  }
  .navigateBack {
    display: flex;
    justify-content: center;
    position: relative;
    bottom: 15px;
  }
  .Loader {
    width: 56px;
    height: 56px;
  }
  .mobileSubmitBtn {
    width: 100% !important;
    background: black !important;
    color: white !important;
    border: none;
    outline: none;
    cursor: pointer;
  }

  .forBorderLeft {
    border-top: 1px solid black;
    width: 33%;
  }
  .forBorderRight {
    border-top: 1px solid black;
    width: 30%;
    position: absolute;
    right: 0px;
  }
  .mobileView {
    width: 100%;
    position: fixed;
    bottom: 0px;
    left: 0;
    background-color: white;
    z-index: 9999999;
  }
  .mobileform {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
  }
  .mobile_input_style {
    border-bottom: 1px solid gray;
    width: 100%;
  }
  .mobileFormDropDown {
    border: none;
    /* border-bottom: 1px solid gray; */
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
    overflow: none;
    width: 80%;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #000000;
  }

  .mobileFormInput {
    width: 100%;
    margin-top: 3rem;
    border: none;
    background-color: transparent;
    outline: none;
    border: none;
    box-shadow: none;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    text-align: left;
    color: #000000;
  }

  .mobileFormInput:hover,
  .mobileFormInput:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
  }

  .mobileFormInput::placeholder {
    position: absolute;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    color: #000000;
  }
  .phoneInput {
    margin-top: 3rem;
  }
  .phoneInputFont {
    margin-top: 7px;
  }
  .callWithEnqueryForm {
    display: none;
  }

  /* end of modal style */
  .enqueryCon {
    width: 100%;
    height: 11vh;
    background-color: black;
    z-index: 1000;

    position: fixed;
    bottom: 0;
  }

  .inputStyle {
    width: 230px !important;
    padding: 10px;
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    color: white;
    font-size: 20px !important;
    text-align: left !important;
  }

  .border_input_style {
    border-bottom: 1px solid gray !important;
  }

  .inputStyle:hover,
  .inputStyle:focus {
    background-color: transparent !important;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  .inputStyle::placeholder {
    font-size: 25px;
    background-color: transparent !important;
  }

  .dropDownStyle {
    background-color: black;
    border: none !important;
    border-bottom: 1px solid gray !important;
    outline: none;
    color: white;
    padding: 10px;
    font-size: 20px;
    width: 230px !important;
    margin-bottom: 2px;
  }

  .enqFormStyle {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .submitBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 18.0488px 30.3171px;
    gap: 18.05px;
    width: 160;
    height: 48px;
    background: #bfbfbf;
    border-radius: 158.829px;
    cursor: pointer;
    font-size: 25px;
    outline: none !important;
    border: none !important;
  }

  .cotentBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .heading {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 54px;
    /* identical to box height, or 135% */

    color: #000000;
  }

  .desc {
    text-align: center;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #727272;
    margin: 20px;
  }

  .okayBtn {
    width: 252.68px;
    height: 64px;
    background: #000000;
    border-radius: 158.829px;
    color: white;
    cursor: pointer;
    font-size: 20px;
  }
  .mobileSubmitBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    gap: 10px;
    width: 296px;
    height: 40px;
    background: #000000 !important;
    border-radius: 88px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 110%;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff !important;
    margin-top: 2rem;
  }
  /* .mobileSubmitBtn:hover {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    gap: 10px;
    width: 296px;
    height: 40px;
    background: #000000 !important;
    border-radius: 88px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 110%;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff !important;
    margin-top: 2rem;
  } */
  .navigateBack {
    display: flex;
  }
  .backText {
    position: relative;
    top: 3px;
    left: 10px;
  }
  .roleStyle {
    display: flex;
    flex-wrap: wrap;
  }
  .roleInputDiv {
    width: 45%;
    position: relative;
    margin: 10px;
    display: flex !important;
  }
  .roleInputDiv span {
    position: relative;
    top: 5px;
    left: 2.5rem;
  }
  .roleInputStyle {
    padding: 0px !important;
    width: 8% !important;
    cursor: pointer;
    position: absolute;
  }
  .roleHeading {
    position: relative;
    right: 260px;
    margin-top: 10px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    color: #000000;
  }
  .CountryAndCity {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 1rem;
  }
  .cityErrorMsg {
    color: red;
    position: relative;
    left: 6.5rem;
    top: 0.5rem;
  }
  .roleErrorMsg {
    color: red;
    position: relative;
    left: 0.5rem;
    top: 0.5rem;
  }
}

@media only screen and (min-width: 1920px) {
  .formContainer {
    display: flex;
    justify-content: center;
    align-items: end;
    flex: 1;
  }
}

@media only screen and (min-width: 2400px) {
  .phoneInput {
    height: 45px !important;
    font-size: 30px !important;
  }

  .mobileFormModalStylee {
    border: none;
    outline: none;
    width: 50%;

    background: rgba(255, 255, 255, 0.9);
    z-index: 999999;
  }
  .Loader {
    width: 56px;
    height: 56px;
  }
  .mobileFormModalStyleeAyodhya {
    border: none;
    outline: none;
    width: 50%;
    height: 92vh;
    background: rgba(255, 255, 255, 0.9);
    z-index: 999999;
  }

  .mobileform {
    padding: 30px;
    margin-top: 0px !important;
  }
  .innerContent {
    border: 1px solid black;
    border-top: none;
    width: 100%;
    position: relative;
  }
  .closeBtn {
    z-index: 99999;
    background: black;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -18px;
    right: -10px;
    cursor: pointer;
  }
  .navigateBack {
    display: flex;
    justify-content: center;
    position: relative;
    bottom: 15px;
  }

  .mobileSubmitBtn {
    width: 100% !important;
    background: black !important;
    color: white !important;
    border: none;
    outline: none;
    cursor: pointer;
  }

  .forBorderLeft {
    border-top: 1px solid black;
    width: 33%;
  }
  .forBorderRight {
    border-top: 1px solid black;
    width: 30%;
    position: absolute;
    right: 0px;
  }
  .mobileView {
    width: 100%;
    position: fixed;
    bottom: 0px;
    left: 0;
    background-color: white;
    z-index: 9999999;
  }
  .mobileform {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
  }
  .mobile_input_style {
    border-bottom: 1px solid gray;
    width: 100%;
  }
  .mobileFormDropDown {
    border: none;
    /* border-bottom: 1px solid gray; */
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
    overflow: none;
    width: 80%;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 150%;
    color: #000000;
  }

  .mobileFormInput {
    width: 100%;
    margin-top: 3rem;
    border: none;
    background-color: transparent;
    outline: none;
    border: none;
    box-shadow: none;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    padding: 1rem;
    line-height: 140%;
    text-align: left;
    color: #000000;
  }

  .mobileFormInput:hover,
  .mobileFormInput:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
  }

  .mobileFormInput::placeholder {
    position: absolute;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 38px;
    line-height: 140%;
    color: #000000;
  }
  .phoneInput {
    margin-top: 5rem;
  }
  .callWithEnqueryForm {
    display: none;
  }

  /* end of modal style */
  .enqueryCon {
    width: 100%;
    height: 11vh;
    background-color: black;
    z-index: 1000;
    position: fixed;
    bottom: 0;
    max-width: 1910px;
  }

  .inputStyle {
    width: 230px !important;
    padding: 10px;
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    color: white;
    font-size: 20px !important;
    text-align: left !important;
  }
  .phoneInputFont {
    font-size: 20px !important;
    margin-top: 5px;
  }
  .border_input_style {
    border-bottom: 1px solid gray !important;
  }

  .inputStyle:hover,
  .inputStyle:focus {
    background-color: transparent !important;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  .inputStyle::placeholder {
    font-size: 24px;
    background-color: transparent !important;
  }

  .dropDownStyle {
    background-color: black;
    border: none !important;
    border-bottom: 1px solid gray !important;
    outline: none;
    color: white;
    font-size: 24px;
    padding: 10px;
    width: 230px !important;
    margin-bottom: 5px;
  }

  .enqFormStyle {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .submitBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 18.0488px 30.3171px;
    gap: 18.05px;
    width: 160px;
    height: 40px;
    background: #bfbfbf;
    border-radius: 158.829px;
    cursor: pointer;
    outline: none !important;
    border: none !important;
  }

  .cotentBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .heading {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 54px;
    /* identical to box height, or 135% */

    color: #000000;
  }

  .desc {
    text-align: center;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #727272;
    margin: 20px;
  }

  .okayBtn {
    width: 252.68px;
    height: 64px;
    background: #000000;
    border-radius: 158.829px;
    color: white;
    cursor: pointer;
    font-size: 20px;
  }
  .mobileSubmitBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    gap: 10px;
    width: 296px;
    height: 80px;
    background: #000000 !important;
    border-radius: 88px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 110%;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff !important;
    margin-top: 2rem;
  }
  /* .mobileSubmitBtn:hover {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    gap: 10px;
    width: 296px;
    height: 40px;
    background: #000000 !important;
    border-radius: 88px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 110%;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff !important;
    margin-top: 2rem;
  } */
  .navigateBack {
    display: flex;
  }
  .backText {
    position: relative;
    top: 3px;
    left: 10px;
    font-size: 40px;
  }
  .roleStyle {
    display: flex;
    flex-wrap: wrap;
  }
  .roleInputDiv {
    width: 45%;
    position: relative;
    margin: 10px;
    display: flex !important;
  }
  .roleInputDiv span {
    position: relative;
    top: 5px;
    left: 2.5rem;
  }
  .roleInputStyle {
    padding: 0px !important;
    width: 8% !important;
    cursor: pointer;
    position: absolute;
  }
  .roleHeading {
    position: relative;
    right: 260px;
    margin-top: 10px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    color: #000000;
  }
  .CountryAndCity {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 1rem;
  }
  .cityErrorMsg {
    color: red;
    position: relative;
    left: 6.5rem;
    top: 0.5rem;
  }
  .roleErrorMsg {
    color: red;
    position: relative;
    left: 0.5rem;
    top: 0.5rem;
  }
}

@media only screen and (min-width: 3000px) {
  .mobileFormModalStylee {
    border: none;
    outline: none;
    width: 50%;
    background: rgba(255, 255, 255, 0.9);
    z-index: 999999;
  }
  .mobileFormModalStyleeAyodhya {
    border: none;
    outline: none;
    width: 50%;
    height: 92vh;
    background: rgba(255, 255, 255, 0.9);
    z-index: 999999;
  }

  .mobileform {
    padding: 30px;
    margin-top: 0px !important;
  }
  .innerContent {
    border: 1px solid black;
    border-top: none;
    width: 100%;
    position: relative;
  }
  .closeBtn {
    z-index: 99999;
    background: black;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -18px;
    right: -10px;
    cursor: pointer;
  }
  .navigateBack {
    display: flex;
    justify-content: center;
    position: relative;
    bottom: 15px;
  }

  .mobileSubmitBtn {
    width: 100% !important;
    background: black !important;
    color: white !important;
    border: none;
    outline: none;
    cursor: pointer;
  }

  .forBorderLeft {
    border-top: 1px solid black;
    width: 33%;
  }
  .forBorderRight {
    border-top: 1px solid black;
    width: 30%;
    position: absolute;
    right: 0px;
  }
  .mobileView {
    width: 100%;
    position: fixed;
    bottom: 0px;
    left: 0;
    background-color: white;
    z-index: 9999999;
  }
  .mobileform {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
  }
  .mobile_input_style {
    border-bottom: 1px solid gray;
    width: 100%;
  }
  .mobileFormDropDown {
    border: none;
    /* border-bottom: 1px solid gray; */
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
    overflow: none;
    width: 80%;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 150%;
    color: #000000;
  }

  .mobileFormInput {
    width: 100%;
    margin-top: 2rem;
    border: none;
    background-color: transparent;
    outline: none;
    border: none;
    box-shadow: none;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    padding: 1rem;
    line-height: 140%;
    text-align: left;
    color: #000000;
  }

  .phoneInput {
    height: 65px !important;
    font-size: 30px !important;
  }

  .mobileFormInput:hover,
  .mobileFormInput:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
  }
  .phoneInput {
    margin-top: 3.5rem;
  }
  .mobileFormInput::placeholder {
    position: absolute;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 38px;
    line-height: 140%;
    color: #000000;
  }

  .callWithEnqueryForm {
    display: none;
  }

  /* end of modal style */
  .enqueryCon {
    width: 100%;
    height: 11vh;
    background-color: black;
    z-index: 1000;
  }

  .inputStyle {
    width: 230px !important;
    padding: 20px;
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    color: white;
    font-size: 35px !important;
    text-align: left !important;
  }

  .phoneInputFont {
    font-size: 28px !important;
  }
  .border_input_style {
    border-bottom: 1px solid gray !important;
  }
  .border_input_style .form-control {
    font-size: 30px;
  }
  .inputStyle:hover,
  .inputStyle:focus {
    background-color: transparent !important;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  .inputStyle::placeholder {
    font-size: 38px;
    background-color: transparent !important;
  }

  .dropDownStyle {
    background-color: black;
    border: none !important;
    border-bottom: 1px solid gray !important;
    outline: none;
    color: white;
    padding: 10px;
    font-size: 35px;

    width: 300px !important;
    margin-top: 50px;
  }

  .enqFormStyle {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .submitBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 18.0488px 30.3171px;
    gap: 18.05px;
    width: 250px;
    height: 80px;
    font-size: 42px;
    background: #bfbfbf;
    border-radius: 158.829px;
    cursor: pointer;
    outline: none !important;
    border: none !important;
  }

  .cotentBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .heading {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 54px;
    /* identical to box height, or 135% */

    color: #000000;
  }

  .desc {
    text-align: center;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #727272;
    margin: 20px;
  }

  .okayBtn {
    width: 252.68px;
    height: 64px;
    background: #000000;
    border-radius: 158.829px;
    color: white;
    cursor: pointer;
    font-size: 20px;
  }
  .mobileSubmitBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    gap: 10px;
    width: 296px;
    height: 80px;
    background: #000000 !important;
    border-radius: 88px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 110%;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff !important;
    margin-top: 2rem;
  }
  /* .mobileSubmitBtn:hover {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    gap: 10px;
    width: 296px;
    height: 40px;
    background: #000000 !important;
    border-radius: 88px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 110%;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff !important;
    margin-top: 2rem;
  } */
  .navigateBack {
    display: flex;
  }
  .backText {
    position: relative;
    top: 3px;
    left: 10px;
    font-size: 40px;
  }
  .roleStyle {
    display: flex;
    flex-wrap: wrap;
  }
  .roleInputDiv {
    width: 45%;
    position: relative;
    margin: 10px;
    display: flex !important;
  }
  .roleInputDiv span {
    position: relative;
    top: 5px;
    left: 2.5rem;
  }
  .roleInputStyle {
    padding: 0px !important;
    width: 8% !important;
    cursor: pointer;
    position: absolute;
  }
  .roleHeading {
    position: relative;
    right: 260px;
    margin-top: 10px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    color: #000000;
  }
  .CountryAndCity {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 1rem;
  }
  .cityErrorMsg {
    color: red;
    position: relative;
    left: 6.5rem;
    top: 0.5rem;
  }
  .roleErrorMsg {
    color: red;
    position: relative;
    left: 0.5rem;
    top: 0.5rem;
  }
}

@media only screen and (min-width: 3600px) {
  .mobileFormModalStylee {
    border: none;
    outline: none;
    width: 30%;
    background: rgba(255, 255, 255, 0.9);
    z-index: 999999;
  }
  .Loader {
    width: 70px;
    height: 70px;
  }
  .mobileFormModalStyleeAyodhya {
    border: none;
    outline: none;
    width: 50%;
    height: 92vh;
    background: rgba(255, 255, 255, 0.9);
    z-index: 999999;
  }

  .mobileform {
    padding: 30px;
    margin-top: 0px !important;
  }
  .innerContent {
    border: 1px solid black;
    border-top: none;
    width: 100%;
    position: relative;
  }
  .closeBtn {
    z-index: 99999;
    background: black;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -35px;
    right: -25px;
    cursor: pointer;
  }
  .navigateBack {
    display: flex;
    justify-content: center;
    position: relative;
    bottom: 15px;
  }

  .mobileSubmitBtn {
    width: 100% !important;
    background: black !important;
    color: white !important;
    border: none;
    outline: none;
    cursor: pointer;
  }

  .forBorderLeft {
    border-top: 1px solid black;
    width: 33%;
  }
  .forBorderRight {
    border-top: 1px solid black;
    width: 30%;
    position: absolute;
    right: 0px;
  }
  .mobileView {
    width: 100%;
    position: fixed;
    bottom: 0px;
    left: 0;
    background-color: white;
    z-index: 9999999;
  }
  .mobileform {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
  }
  .mobile_input_style {
    border-bottom: 1px solid gray;
    width: 100%;
  }
  .mobileFormDropDown {
    border: none;
    /* border-bottom: 1px solid gray; */
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
    overflow: none;
    width: 100%;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 150%;
    color: #000000;
  }

  .mobileFormInput {
    width: 100%;
    margin-top: 1rem;
    border: none;
    background-color: transparent;
    outline: none;
    border: none;
    box-shadow: none;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    padding: 2rem;
    line-height: 140%;
    text-align: left;
    color: #000000;
  }

  .mobileFormInput:hover,
  .mobileFormInput:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
  }

  .mobileFormInput::placeholder {
    position: absolute;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 140%;
    color: #000000;
  }

  .callWithEnqueryForm {
    display: none;
  }

  /* end of modal style */
  .enqueryCon {
    width: 100%;
    height: 11vh !important;
    background-color: black;
    z-index: 1000;
  }
  .border_input_style .form-control {
    font-size: 30px;
  }
  .inputStyle {
    width: 300px !important;
    padding: 30px;
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    color: white;
    font-size: 42px !important;
    text-align: left !important;
  }

  .border_input_style {
    border-bottom: 1px solid gray !important;
  }
  .phoneInputFont {
    font-size: 35px !important;
    margin-top: 55px;
  }
  .inputPhone {
    height: 80px !important;
    font-size: 30px !important;
    width: 300px !important;
  }

  .inputStyle:hover,
  .inputStyle:focus {
    background-color: transparent !important;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  .inputStyle::placeholder {
    font-size: 35px;
    background-color: transparent !important;
  }

  .dropDownStyle {
    background-color: black;
    border: none !important;
    border-bottom: 1px solid gray !important;
    outline: none;
    color: white;
    padding: 10px;
    width: 350px !important;
    margin-top: 50px;
  }

  .enqFormStyle {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .submitBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 18.0488px 30.3171px;
    gap: 18.05px;
    width: 350px;
    height: 120px;
    background: #bfbfbf;
    border-radius: 158.829px;
    cursor: pointer;
    outline: none !important;
    border: none !important;
    font-size: 42px !important;
  }

  .cotentBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .heading {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 54px;
    /* identical to box height, or 135% */

    color: #000000;
  }

  .desc {
    text-align: center;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #727272;
    margin: 20px;
  }

  .okayBtn {
    width: 252.68px;
    height: 64px;
    background: #000000;
    border-radius: 158.829px;
    color: white;
    cursor: pointer;
    font-size: 20px;
  }
  .mobileSubmitBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    gap: 10px;
    width: 296px;
    height: 100px;
    background: #000000 !important;
    border-radius: 88px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 110%;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff !important;
    margin-top: 3rem;
  }
  /* .mobileSubmitBtn:hover {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    gap: 10px;
    width: 296px;
    height: 40px;
    background: #000000 !important;
    border-radius: 88px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 110%;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff !important;
    margin-top: 2rem;
  } */
  .navigateBack {
    display: flex;
  }
  .backText {
    position: relative;
    top: 3px;
    left: 10px;
    font-size: 48px;
  }
  .roleStyle {
    display: flex;
    flex-wrap: wrap;
  }
  .roleInputDiv {
    width: 45%;
    position: relative;
    margin: 10px;
    display: flex !important;
  }
  .roleInputDiv span {
    position: relative;
    top: 5px;
    left: 2.5rem;
  }
  .roleInputStyle {
    padding: 0px !important;
    width: 8% !important;
    cursor: pointer;
    position: absolute;
  }
  .roleHeading {
    position: relative;
    right: 260px;
    margin-top: 10px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    color: #000000;
  }
  .CountryAndCity {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 1rem;
  }
  .cityErrorMsg {
    color: red;
    position: relative;
    left: 6.5rem;
    top: 0.5rem;
  }
  .roleErrorMsg {
    color: red;
    position: relative;
    left: 0.5rem;
    top: 0.5rem;
  }
  .closeIcon {
    width: 80px;
    height: 80px;
  }

  .phoneInput,
  .flag-dropdown,
  .enquery {
    height: 65px !important;
    font-size: 30px !important;
  }
}
@media only screen and (min-width: 4200px) {
  .dropDownStyle {
    /* margin-top: 27px; */
  }
}
@media only screen and (max-width: 1400px) and (min-width: 1130px) {
  .enqueryCon{
  height:15%
  }
}

/* for footer form */

/* @media only screen and (min-width: 600px){
  .callWithEnqueryForm {
    width: 100%;
    position: relative;
    bottom: 10px;
    padding-top: 20px;
    background-color: white;
    height: 100%;
    display: flex;
    justify-content: space-around;
  }

  .enQueryBtn {
    border-radius: 60px;
    background-color: transparent;
    width: 164px;
    height: 40px;
    left: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 110%;
    outline: none;
    border: none;
    border: 2px solid gray;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #000000;
  }
} */

/* for footer form */
/* @media only screen and (min-width: 5000px) {
  .border_input_style {
    margin-bottom: 20px;
  }
} */
/* @media only screen and (min-width: 4200px) {
  .mobileFormModalStylee {
    border: none;
    outline: none;
    width: 50%;

    background: rgba(255, 255, 255, 0.9);
    z-index: 999999;
  }
  .mobileFormModalStyleeAyodhya {
    border: none;
    outline: none;
    width: 50%;
    height: 92vh;
    background: rgba(255, 255, 255, 0.9);
    z-index: 999999;
  }

  .mobileform {
    padding: 30px;
    margin-top: 0px !important;
  }
  .innerContent {
    border: 1px solid black;
    border-top: none;
    width: 100%;
    position: relative;
  }
  .closeBtn {
    z-index: 99999;
    background: black;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -35px;
    right: -25px;
    cursor: pointer;
  }
  .navigateBack {
    display: flex;
    justify-content: center;
    position: relative;
    bottom: 15px;
  }

  .mobileSubmitBtn {
    width: 100% !important;
    background: black !important;
    color: white !important;
    border: none;
    outline: none;
    cursor: pointer;
  }

  .forBorderLeft {
    border-top: 1px solid black;
    width: 33%;
  }
  .forBorderRight {
    border-top: 1px solid black;
    width: 30%;
    position: absolute;
    right: 0px;
  }
  .mobileView {
    width: 100%;
    position: fixed;
    bottom: 0px;
    left: 0;
    background-color: white;
    z-index: 9999999;
  }
  .mobileform {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
  }
  .mobile_input_style {
    border-bottom: 1px solid gray;
    width: 100%;
  }
  .mobileFormDropDown {
    border: none;
    border-bottom: 1px solid gray;
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
    overflow: none;
    width: 80%;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 58px;
    line-height: 150%;
    color: #000000;
  }

  .mobileFormInput {
    width: 100%;
    margin-top: 9rem;
    border: none;
    background-color: transparent;
    outline: none;
    border: none;
    box-shadow: none;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 60px;
    padding: 3rem;
    line-height: 140%;
    text-align: left;
    color: #000000;
  }

  .mobileFormInput:hover,
  .mobileFormInput:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
  }

  .mobileFormInput::placeholder {
    position: absolute;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 58px;
    line-height: 140%;
    color: #000000;
  }

  .callWithEnqueryForm {
    display: none;
  }

  .enqueryCon {
    width: 100%;
    height: 250px;
    background-color: black;
    z-index: 1000;
  }

  .inputStyle {
    width: 300px !important;
    padding: 30px;
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    color: white;
    font-size: 15px !important;
    text-align: left !important;
  }

  .border_input_style {
    border-bottom: 1px solid gray !important;
  }

  .inputStyle:hover,
  .inputStyle:focus {
    background-color: transparent !important;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  .inputStyle::placeholder {
    font-size: 52px;
    background-color: transparent !important;
  }

  .dropDownStyle {
    background-color: black;
    border: none !important;
    border-bottom: 1px solid gray !important;
    outline: none;
    color: white;
    font-size: 52px;
    padding: 10px;
    width: 450px !important;
  }

  .enqFormStyle {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .submitBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 18.0488px 30.3171px;
    gap: 18.05px;
    width: 300px !important;
    font-size: 52px !important;
    height: 150px;
    background: #bfbfbf;
    border-radius: 158.829px;
    cursor: pointer;
    font-size: 52px;
    outline: none !important;
    border: none !important;
  }

  .cotentBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .heading {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 54px;
    color: #000000;
  }

  .desc {
    text-align: center;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #727272;
    margin: 20px;
  }

  .okayBtn {
    width: 252.68px;
    height: 64px;
    background: #000000;
    border-radius: 158.829px;
    color: white;
    cursor: pointer;
    font-size: 20px;
  }
  .mobileSubmitBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    gap: 10px;
    width: 296px;
    height: 140px;
    background: #000000 !important;
    border-radius: 88px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 56px;
    line-height: 110%;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff !important;
    margin-top: 4rem;
  }
  .mobileSubmitBtn:hover {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    gap: 10px;
    width: 296px;
    height: 40px;
    background: #000000 !important;
    border-radius: 88px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 110%;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff !important;
    margin-top: 2rem;
  }
  .navigateBack {
    display: flex;
  }
  .backText {
    position: relative;
    top: 3px;
    left: 10px;
    font-size: 56px;
  }
  .roleStyle {
    display: flex;
    flex-wrap: wrap;
  }
  .roleInputDiv {
    width: 45%;
    position: relative;
    margin: 10px;
    display: flex !important;
  }
  .roleInputDiv span {
    position: relative;
    top: 5px;
    left: 2.5rem;
  }
  .roleInputStyle {
    padding: 0px !important;
    width: 8% !important;
    cursor: pointer;
    position: absolute;
  }
  .roleHeading {
    position: relative;
    right: 260px;
    margin-top: 10px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    color: #000000;
  }
  .CountryAndCity {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 1rem;
  }
  .cityErrorMsg {
    color: red;
    position: relative;
    left: 6.5rem;
    top: 0.5rem;
  }
  .roleErrorMsg {
    color: red;
    position: relative;
    left: 0.5rem;
    top: 0.5rem;
  }
  .closeIcon {
    width: 80px;
    height: 80px;
  }
} */

/* @media only screen and (min-width: 4800px) {
  .mobileFormModalStylee {
    border: none;
    outline: none;
    width: 50%;

    background: rgba(255, 255, 255, 0.9);
    z-index: 999999;
  }
  .mobileFormModalStyleeAyodhya {
    border: none;
    outline: none;
    width: 50%;
    height: 92vh;
    background: rgba(255, 255, 255, 0.9);
    z-index: 999999;
  }

  .mobileform {
    padding: 30px;
    margin-top: 0px !important;
  }
  .innerContent {
    border: 1px solid black;
    border-top: none;
    width: 100%;
    position: relative;
  }
  .closeBtn {
    z-index: 99999;
    background: black;
    width: 170px;
    height: 170px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -35px;
    right: -25px;
    cursor: pointer;
  }
  .navigateBack {
    display: flex;
    justify-content: center;
    position: relative;
    bottom: 15px;
  }

  .mobileSubmitBtn {
    width: 100% !important;
    background: black !important;
    color: white !important;
    border: none;
    outline: none;
    cursor: pointer;
  }

  .forBorderLeft {
    border-top: 1px solid black;
    width: 33%;
  }
  .forBorderRight {
    border-top: 1px solid black;
    width: 30%;
    position: absolute;
    right: 0px;
  }
  .mobileView {
    width: 100%;
    position: fixed;
    bottom: 0px;
    left: 0;
    background-color: white;
    z-index: 9999999;
  }
  .mobileform {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
  }
  .mobile_input_style {
    border-bottom: 1px solid gray;
    width: 100%;
  }
  .mobileFormDropDown {
    border: none;
    border-bottom: 1px solid gray;
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
    overflow: none;
    width: 80%;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    line-height: 150%;
    color: #000000;
  }

  .mobileFormInput {
    width: 100%;
    margin-top: 9rem;
    border: none;
    background-color: transparent;
    outline: none;
    border: none;
    box-shadow: none;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 68px;
    padding: 3rem;
    line-height: 140%;
    text-align: left;
    color: #000000;
  }

  .mobileFormInput:hover,
  .mobileFormInput:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
  }

  .mobileFormInput::placeholder {
    position: absolute;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    line-height: 140%;
    color: #000000;
  }

  .callWithEnqueryForm {
    display: none;
  }

  .enqueryCon {
    width: 100%;
    height: 300px !important;
    background-color: black;
    z-index: 1000;
  }

  .inputStyle {
    width: 380px !important;
    padding: 40px;
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    color: white;

    font-size: 15px !important;
    text-align: left !important;
  }

  .border_input_style {
    border-bottom: 1px solid gray !important;
  }

  .inputStyle:hover,
  .inputStyle:focus {
    background-color: transparent !important;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  .inputStyle::placeholder {
    font-size: 62px;
    background-color: transparent !important;
  }

  .dropDownStyle {
    background-color: black;
    border: none !important;
    border-bottom: 1px solid gray !important;
    outline: none;
    color: white;
    padding: 10px;
    font-size: 58px !important;
    width: 400px !important;
  }

  .enqFormStyle {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .submitBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 18.0488px 30.3171px;
    gap: 18.05px;
    width: 350px !important;
    font-size: 62px !important;
    height: 100px;
    background: #bfbfbf;
    border-radius: 158.829px;
    cursor: pointer;
    outline: none !important;
    border: none !important;
  }

  .cotentBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .heading {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 54px;

    color: #000000;
  }

  .desc {
    text-align: center;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #727272;
    margin: 20px;
  }

  .okayBtn {
    width: 252.68px;
    height: 64px;
    background: #000000;
    border-radius: 158.829px;
    color: white;
    cursor: pointer;
    font-size: 20px;
  }
  .mobileSubmitBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    gap: 10px;
    width: 296px;
    height: 160px;
    background: #000000 !important;
    border-radius: 88px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    line-height: 110%;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff !important;
    margin-top: 5rem;
  }
  .mobileSubmitBtn:hover {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    gap: 10px;
    width: 296px;
    height: 40px;
    background: #000000 !important;
    border-radius: 88px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 110%;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff !important;
    margin-top: 2rem;
  }
  .navigateBack {
    display: flex;
  }
  .backText {
    position: relative;
    top: 3px;
    left: 10px;
    font-size: 64px;
  }
  .roleStyle {
    display: flex;
    flex-wrap: wrap;
  }
  .roleInputDiv {
    width: 45%;
    position: relative;
    margin: 10px;
    display: flex !important;
  }
  .roleInputDiv span {
    position: relative;
    top: 5px;
    left: 2.5rem;
  }
  .roleInputStyle {
    padding: 0px !important;
    width: 8% !important;
    cursor: pointer;
    position: absolute;
  }
  .roleHeading {
    position: relative;
    right: 260px;
    margin-top: 10px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    color: #000000;
  }
  .CountryAndCity {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 1rem;
  }
  .cityErrorMsg {
    color: red;
    position: relative;
    left: 6.5rem;
    top: 0.5rem;
  }
  .roleErrorMsg {
    color: red;
    position: relative;
    left: 0.5rem;
    top: 0.5rem;
  }
  .closeIcon {
    width: 80px;
    height: 80px;
  }
} */

/* @media only screen and (min-width: 5400px) {
  .mobileFormModalStylee {
    border: none;
    outline: none;
    width: 50%;
    background: rgba(255, 255, 255, 0.9);
    z-index: 999999;
  }
  .mobileFormModalStyleeAyodhya {
    border: none;
    outline: none;
    width: 50%;
    height: 92vh;
    background: rgba(255, 255, 255, 0.9);
    z-index: 999999;
  }

  .mobileform {
    padding: 30px;
    margin-top: 0px !important;
  }
  .innerContent {
    border: 1px solid black;
    border-top: none;
    width: 100%;
    position: relative;
  }
  .closeBtn {
    z-index: 99999;
    background: black;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -35px;
    right: -25px;
    cursor: pointer;
  }
  .navigateBack {
    display: flex;
    justify-content: center;
    position: relative;
    bottom: 15px;
  }

  .mobileSubmitBtn {
    width: 100% !important;
    background: black !important;
    color: white !important;
    border: none;
    outline: none;
    cursor: pointer;
  }

  .forBorderLeft {
    border-top: 1px solid black;
    width: 33%;
  }
  .forBorderRight {
    border-top: 1px solid black;
    width: 30%;
    position: absolute;
    right: 0px;
  }
  .mobileView {
    width: 100%;
    position: fixed;
    bottom: 0px;
    left: 0;
    background-color: white;
    z-index: 9999999;
  }
  .mobileform {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
  }
  .mobile_input_style {
    border-bottom: 1px solid gray;
    width: 100%;
  }
  .mobileFormDropDown {
    border: none;
    border-bottom: 1px solid gray;
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
    overflow: none;
    width: 80%;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 72px;
    line-height: 150%;
    color: #000000;
  }

  .mobileFormInput {
    width: 100%;
    margin-top: 9rem;
    border: none;
    background-color: transparent;
    outline: none;
    border: none;
    box-shadow: none;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 72px;
    padding: 4rem;
    line-height: 140%;
    text-align: left;
    color: #000000;
  }

  .mobileFormInput:hover,
  .mobileFormInput:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
  }

  .mobileFormInput::placeholder {
    position: absolute;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 72px;
    line-height: 140%;
    color: #000000;
  }

  .callWithEnqueryForm {
    display: none;
  }

  .enqueryCon {
    width: 100%;
    height: 70px;
    background-color: black;
    z-index: 1000;
  }

  .inputStyle {
    width: 400px !important;
    padding: 40px;
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    color: white;
    font-size: 52px !important;
    text-align: left !important;
  }

  .border_input_style {
    border-bottom: 1px solid gray !important;
  }

  .inputStyle:hover,
  .inputStyle:focus {
    background-color: transparent !important;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  .inputStyle::placeholder {
    font-size: 15px;
    background-color: transparent !important;
    font-size: 68px;
  }

  .dropDownStyle {
    background-color: black;
    border: none !important;
    border-bottom: 1px solid gray !important;
    outline: none;
    color: white;
    font-size: 52px !important;
    padding: 10px;
    width: 450px !important;
    font-size: 62px !important ;
  }

  .enqFormStyle {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .submitBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 18.0488px 30.3171px;
    gap: 18.05px;
    width: 550px !important;
    font-size: 72px !important;
    height: 140px !important;
    background: #bfbfbf;
    border-radius: 158.829px;
    cursor: pointer;
    outline: none !important;
    border: none !important;
  }

  .cotentBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .heading {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 54px;

    color: #000000;
  }

  .desc {
    text-align: center;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #727272;
    margin: 20px;
  }

  .okayBtn {
    width: 252.68px;
    height: 64px;
    background: #000000;
    border-radius: 158.829px;
    color: white;
    cursor: pointer;
    font-size: 20px;
  }
  .mobileSubmitBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    gap: 10px;
    width: 296px;
    height: 180px;
    background: #000000 !important;
    border-radius: 88px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 72px;
    line-height: 110%;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff !important;
    margin-top: 6rem;
  }
  .mobileSubmitBtn:hover {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    gap: 10px;
    width: 296px;
    height: 40px;
    background: #000000 !important;
    border-radius: 88px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 110%;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff !important;
    margin-top: 2rem;
  }
  .navigateBack {
    display: flex;
  }
  .backText {
    position: relative;
    top: 3px;
    left: 10px;
    font-size: 72px;
  }
  .roleStyle {
    display: flex;
    flex-wrap: wrap;
  }
  .roleInputDiv {
    width: 45%;
    position: relative;
    margin: 10px;
    display: flex !important;
  }
  .roleInputDiv span {
    position: relative;
    top: 5px;
    left: 2.5rem;
  }
  .roleInputStyle {
    padding: 0px !important;
    width: 8% !important;
    cursor: pointer;
    position: absolute;
  }
  .roleHeading {
    position: relative;
    right: 260px;
    margin-top: 10px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    color: #000000;
  }
  .CountryAndCity {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 1rem;
  }
  .cityErrorMsg {
    color: red;
    position: relative;
    left: 6.5rem;
    top: 0.5rem;
  }
  .roleErrorMsg {
    color: red;
    position: relative;
    left: 0.5rem;
    top: 0.5rem;
  }
  .closeIcon {
    width: 100px;
    height: 100px;
  }
} */

/* @media only screen and (min-width: 6000px) {
  .mobileFormModalStylee {
    border: none;
    outline: none;
    width: 50%;

    background: rgba(255, 255, 255, 0.9);
    z-index: 999999;
  }
  .mobileFormModalStyleeAyodhya {
    border: none;
    outline: none;
    width: 50%;
    height: 92vh;
    background: rgba(255, 255, 255, 0.9);
    z-index: 999999;
  }

  .mobileform {
    padding: 30px;
    margin-top: 0px !important;
  }
  .innerContent {
    border: 1px solid black;
    border-top: none;
    width: 100%;
    position: relative;
  }
  .closeBtn {
    z-index: 99999;
    background: black;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -35px;
    right: -25px;
    cursor: pointer;
  }
  .navigateBack {
    display: flex;
    justify-content: center;
    position: relative;
    bottom: 15px;
  }

  .mobileSubmitBtn {
    width: 100% !important;
    background: black !important;
    color: white !important;
    border: none;
    outline: none;
    cursor: pointer;
  }

  .forBorderLeft {
    border-top: 1px solid black;
    width: 33%;
  }
  .forBorderRight {
    border-top: 1px solid black;
    width: 30%;
    position: absolute;
    right: 0px;
  }
  .mobileView {
    width: 100%;
    position: fixed;
    bottom: 0px;
    left: 0;
    background-color: white;
    z-index: 9999999;
  }
  .mobileform {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
  }
  .mobile_input_style {
    border-bottom: 1px solid gray;
    width: 100%;
  }
  .mobileFormDropDown {
    border: none;
    border-bottom: 1px solid gray;
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
    overflow: none;
    width: 80%;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 72px;
    line-height: 150%;
    color: #000000;
  }

  .mobileFormInput {
    width: 100%;
    margin-top: 9rem;
    border: none;
    background-color: transparent;
    outline: none;
    border: none;
    box-shadow: none;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 72px;
    padding: 4rem;
    line-height: 140%;
    text-align: left;
    color: #000000;
  }

  .mobileFormInput:hover,
  .mobileFormInput:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
  }

  .mobileFormInput::placeholder {
    position: absolute;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 72px;
    line-height: 140%;
    color: #000000;
  }

  .callWithEnqueryForm {
    display: none;
  }

  .enqueryCon {
    width: 100%;
    height: 70px;
    background-color: black;
    z-index: 1000;
  }

  .inputStyle {
    width: 450px !important;
    padding: 60px;
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    color: white;
    font-size: 15px !important;
    text-align: left !important;
  }

  .border_input_style {
    border-bottom: 1px solid gray !important;
  }

  .inputStyle:hover,
  .inputStyle:focus {
    background-color: transparent !important;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  .inputStyle::placeholder {
    font-size: 72px;
    background-color: transparent !important;
  }

  .dropDownStyle {
    background-color: black;
    border: none !important;
    border-bottom: 1px solid gray !important;
    outline: none;
    color: white;
    font-size: 72px !important;
    padding: 10px;

    width: 630px !important;
  }

  .enqFormStyle {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .submitBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 18.0488px 30.3171px;
    gap: 18.05px;
    width: 152.68px;
    height: 40px;
    background: #bfbfbf;
    border-radius: 158.829px;
    cursor: pointer;
    outline: none !important;
    border: none !important;
  }

  .cotentBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .heading {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 54px;

    color: #000000;
  }

  .desc {
    text-align: center;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #727272;
    margin: 20px;
  }

  .okayBtn {
    width: 252.68px;
    height: 64px;
    background: #000000;
    border-radius: 158.829px;
    color: white;
    cursor: pointer;
    font-size: 20px;
  }
  .mobileSubmitBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    gap: 10px;
    width: 296px;
    height: 180px;
    background: #000000 !important;
    border-radius: 88px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 72px;
    line-height: 110%;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff !important;
    margin-top: 6rem;
  }
  .mobileSubmitBtn:hover {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    gap: 10px;
    width: 296px;
    height: 40px;
    background: #000000 !important;
    border-radius: 88px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 110%;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff !important;
    margin-top: 2rem;
  }
  .navigateBack {
    display: flex;
  }
  .backText {
    position: relative;
    top: 3px;
    left: 10px;
    font-size: 72px;
  }
  .roleStyle {
    display: flex;
    flex-wrap: wrap;
  }
  .roleInputDiv {
    width: 45%;
    position: relative;
    margin: 10px;
    display: flex !important;
  }
  .roleInputDiv span {
    position: relative;
    top: 5px;
    left: 2.5rem;
  }
  .roleInputStyle {
    padding: 0px !important;
    width: 8% !important;
    cursor: pointer;
    position: absolute;
  }
  .roleHeading {
    position: relative;
    right: 260px;
    margin-top: 10px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    color: #000000;
  }
  .CountryAndCity {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 1rem;
  }
  .cityErrorMsg {
    color: red;
    position: relative;
    left: 6.5rem;
    top: 0.5rem;
  }
  .roleErrorMsg {
    color: red;
    position: relative;
    left: 0.5rem;
    top: 0.5rem;
  }
  .closeIcon {
    width: 100px;
    height: 100px;
  }
} */

/* @media only screen and (min-width: 6600px) {
  .mobileFormModalStylee {
    border: none;
    outline: none;
    width: 50%;

    background: rgba(255, 255, 255, 0.9);
    z-index: 999999;
  }
  .mobileFormModalStyleeAyodhya {
    border: none;
    outline: none;
    width: 50%;
    height: 92vh;
    background: rgba(255, 255, 255, 0.9);
    z-index: 999999;
  }

  .mobileform {
    padding: 30px;
    margin-top: 0px !important;
  }
  .innerContent {
    border: 1px solid black;
    border-top: none;
    width: 100%;
    position: relative;
  }
  .closeBtn {
    z-index: 99999;
    background: black;
    width: 240px;
    height: 240px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -45px;
    right: -35px;
    cursor: pointer;
  }
  .navigateBack {
    display: flex;
    justify-content: center;
    position: relative;
    bottom: 15px;
  }

  .mobileSubmitBtn {
    width: 100% !important;
    background: black !important;
    color: white !important;
    border: none;
    outline: none;
    cursor: pointer;
  }

  .forBorderLeft {
    border-top: 1px solid black;
    width: 33%;
  }
  .forBorderRight {
    border-top: 1px solid black;
    width: 30%;
    position: absolute;
    right: 0px;
  }
  .mobileView {
    width: 100%;
    position: fixed;
    bottom: 0px;
    left: 0;
    background-color: white;
    z-index: 9999999;
  }
  .mobileform {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
  }
  .mobile_input_style {
    border-bottom: 1px solid gray;
    width: 100%;
  }
  .mobileFormDropDown {
    border: none;
    border-bottom: 1px solid gray;
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
    overflow: none;
    width: 80%;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 88px;
    line-height: 150%;
    color: #000000;
  }

  .mobileFormInput {
    width: 100%;
    margin-top: 9rem;
    border: none;
    background-color: transparent;
    outline: none;
    border: none;
    box-shadow: none;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 88px;
    padding: 4rem;
    line-height: 140%;
    text-align: left;
    color: #000000;
  }

  .mobileFormInput:hover,
  .mobileFormInput:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
  }

  .mobileFormInput::placeholder {
    position: absolute;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 88px;
    line-height: 140%;
    color: #000000;
  }

  .callWithEnqueryForm {
    display: none;
  }

  .enqueryCon {
    width: 100%;
    height: 70px;
    background-color: black;
    z-index: 1000;
  }

  .inputStyle {
    width: 630px !important;
    padding: 80px;
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    color: white;
    font-size: 82px !important;
    text-align: left !important;
  }

  .border_input_style {
    border-bottom: 1px solid gray !important;
  }

  .inputStyle:hover,
  .inputStyle:focus {
    background-color: transparent !important;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  .inputStyle::placeholder {
    font-size: 82px !important;
    background-color: transparent !important;
  }

  .dropDownStyle {
    background-color: black;
    border: none !important;
    border-bottom: 1px solid gray !important;
    outline: none;
    color: white;
    font-size: 82px !important;
    padding: 10px;
    width: 630px !important;
  }

  .enqFormStyle {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .submitBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 18.0488px 30.3171px;
    gap: 18.05px;
    width: 152.68px;
    height: 40px;
    background: #bfbfbf;
    font-size: 82px !important;
    border-radius: 158.829px;
    cursor: pointer;
    outline: none !important;
    border: none !important;
  }

  .cotentBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .heading {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 54px;

    color: #000000;
  }

  .desc {
    text-align: center;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #727272;
    margin: 20px;
  }

  .okayBtn {
    width: 252.68px;
    height: 64px;
    background: #000000;
    border-radius: 158.829px;
    color: white;
    cursor: pointer;
    font-size: 20px;
  }
  .mobileSubmitBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    gap: 10px;
    width: 296px;
    height: 200px;
    background: #000000 !important;
    border-radius: 88px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 80px;
    line-height: 110%;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff !important;
    margin-top: 7rem;
  }
  .mobileSubmitBtn:hover {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    gap: 10px;
    width: 296px;
    height: 40px;
    background: #000000 !important;
    border-radius: 88px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 110%;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff !important;
    margin-top: 2rem;
  }
  .navigateBack {
    display: flex;
  }
  .backText {
    position: relative;
    top: 3px;
    left: 10px;
    font-size: 100px;
  }
  .roleStyle {
    display: flex;
    flex-wrap: wrap;
  }
  .roleInputDiv {
    width: 45%;
    position: relative;
    margin: 10px;
    display: flex !important;
  }
  .roleInputDiv span {
    position: relative;
    top: 5px;
    left: 2.5rem;
  }
  .roleInputStyle {
    padding: 0px !important;
    width: 8% !important;
    cursor: pointer;
    position: absolute;
  }
  .roleHeading {
    position: relative;
    right: 260px;
    margin-top: 10px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    color: #000000;
  }
  .CountryAndCity {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 1rem;
  }
  .cityErrorMsg {
    color: red;
    position: relative;
    left: 6.5rem;
    top: 0.5rem;
  }
  .roleErrorMsg {
    color: red;
    position: relative;
    left: 0.5rem;
    top: 0.5rem;
  }
  .closeIcon {
    width: 140px;
    height: 140px;
  }
} */

/* @media only screen and (min-width: 7200px) {
  .mobileFormModalStylee {
    border: none;
    outline: none;
    width: 50%;

    background: rgba(255, 255, 255, 0.9);
    z-index: 999999;
  }
  .Loader {
    width: 125px;
    height: 186px;
  }
  .mobileFormModalStyleeAyodhya {
    border: none;
    outline: none;
    width: 50%;
    height: 80vh;
    background: rgba(255, 255, 255, 0.9);
    z-index: 999999;
  }

  .mobileform {
    padding: 30px;
    margin-top: 0px !important;
  }
  .innerContent {
    border: 1px solid black;
    border-top: none;
    width: 100%;
    position: relative;
  }
  .closeBtn {
    z-index: 99999;
    background: black;
    width: 240px;
    height: 240px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -45px;
    right: -35px;
    cursor: pointer;
  }
  .navigateBack {
    display: flex;
    justify-content: center;
    position: relative;
    bottom: 15px;
  }

  .mobileSubmitBtn {
    width: 100% !important;
    background: black !important;
    color: white !important;
    border: none;
    outline: none;
    cursor: pointer;
  }

  .forBorderLeft {
    border-top: 1px solid black;
    width: 33%;
  }
  .forBorderRight {
    border-top: 1px solid black;
    width: 30%;
    position: absolute;
    right: 0px;
  }
  .mobileView {
    width: 100%;
    position: fixed;
    bottom: 0px;
    left: 0;
    background-color: white;
    z-index: 9999999;
  }
  .mobileform {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
  }
  .mobile_input_style {
    border-bottom: 1px solid gray;
    width: 100%;
  }
  .mobileFormDropDown {
    border: none;
    border-bottom: 1px solid gray;
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
    overflow: none;
    width: 80%;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 88px;
    line-height: 150%;
    color: #000000;
  }

  .mobileFormInput {
    width: 100%;
    margin-top: 9rem;
    border: none;
    background-color: transparent;
    outline: none;
    border: none;
    box-shadow: none;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 88px;
    padding: 4rem;
    line-height: 140%;
    text-align: left;
    color: #000000;
  }

  .mobileFormInput:hover,
  .mobileFormInput:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
  }

  .mobileFormInput::placeholder {
    position: absolute;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 88px;
    line-height: 140%;
    color: #000000;
  }

  .callWithEnqueryForm {
    display: none;
  }

  .enqueryCon {
    width: 100%;
    height: 70px;
    background-color: black;
    z-index: 1000;
  }

  .inputStyle {
    width: 630px !important;
    padding: 100px;
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    color: white;
    font-size: 15px !important;

    text-align: left !important;
  }

  .border_input_style {
    border-bottom: 1px solid gray !important;
  }

  .inputStyle:hover,
  .inputStyle:focus {
    background-color: transparent !important;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  .inputStyle::placeholder {
    font-size: 92px !important;
    background-color: transparent !important;
  }

  .dropDownStyle {
    background-color: black;
    border: none !important;
    border-bottom: 1px solid gray !important;
    outline: none;
    color: white;
    padding: 10px;
    width: 630px !important;
  }

  .enqFormStyle {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .submitBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 18.0488px 30.3171px;
    gap: 18.05px;
    width: 600px;
    height: 300px;
    background: #bfbfbf;
    border-radius: 158.829px;
    cursor: pointer;
    outline: none !important;
    border: none !important;
  }

  .cotentBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .heading {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 54px;

    color: #000000;
  }

  .desc {
    text-align: center;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #727272;
    margin: 20px;
  }

  .okayBtn {
    width: 252.68px;
    height: 64px;
    background: #000000;
    border-radius: 158.829px;
    color: white;
    cursor: pointer;
    font-size: 20px;
  }
  .mobileSubmitBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    gap: 10px;
    width: 296px;
    height: 200px;
    background: #000000 !important;
    border-radius: 88px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 80px;
    line-height: 110%;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff !important;
    margin-top: 7rem;
  }
  .mobileSubmitBtn:hover {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    gap: 10px;
    width: 296px;
    height: 40px;
    background: #000000 !important;
    border-radius: 88px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 110%;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff !important;
    margin-top: 2rem;
  }
  .navigateBack {
    display: flex;
  }
  .backText {
    position: relative;
    top: 3px;
    left: 10px;
    font-size: 100px;
  }
  .roleStyle {
    display: flex;
    flex-wrap: wrap;
  }
  .roleInputDiv {
    width: 45%;
    position: relative;
    margin: 10px;
    display: flex !important;
  }
  .roleInputDiv span {
    position: relative;
    top: 5px;
    left: 2.5rem;
  }
  .roleInputStyle {
    padding: 0px !important;
    width: 8% !important;
    cursor: pointer;
    position: absolute;
  }
  .roleHeading {
    position: relative;
    right: 260px;
    margin-top: 10px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    color: #000000;
  }
  .CountryAndCity {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 1rem;
  }
  .cityErrorMsg {
    color: red;
    position: relative;
    left: 6.5rem;
    top: 0.5rem;
  }
  .roleErrorMsg {
    color: red;
    position: relative;
    left: 0.5rem;
    top: 0.5rem;
  }
  .closeIcon {
    width: 140px;
    height: 140px;
  }
  .countryStyle {
    width: 500px;
    height: 500px;
    font-size: 100px;
  }
} */

/* @media only screen and (min-width: 7800px) {
  .mobileFormModalStylee {
    border: none;
    outline: none;
    width: 50%;

    background: rgba(255, 255, 255, 0.9);
    z-index: 999999;
  }
  .mobileFormModalStyleeAyodhya {
    border: none;
    outline: none;
    width: 50%;
    height: 92vh;
    background: rgba(255, 255, 255, 0.9);
    z-index: 999999;
  }

  .mobileform {
    padding: 30px;
    margin-top: 0px !important;
  }
  .innerContent {
    border: 1px solid black;
    border-top: none;
    width: 100%;
    position: relative;
  }
  .closeBtn {
    z-index: 99999;
    background: black;
    width: 240px;
    height: 240px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -45px;
    right: -35px;
    cursor: pointer;
  }
  .navigateBack {
    display: flex;
    justify-content: center;
    position: relative;
    bottom: 15px;
  }

  .mobileSubmitBtn {
    width: 100% !important;
    background: black !important;
    color: white !important;
    border: none;
    outline: none;
    cursor: pointer;
  }

  .forBorderLeft {
    border-top: 1px solid black;
    width: 33%;
  }
  .forBorderRight {
    border-top: 1px solid black;
    width: 30%;
    position: absolute;
    right: 0px;
  }
  .mobileView {
    width: 100%;
    position: fixed;
    bottom: 0px;
    left: 0;
    background-color: white;
    z-index: 9999999;
  }
  .mobileform {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
  }
  .mobile_input_style {
    border-bottom: 1px solid gray;
    width: 100%;
  }
  .mobileFormDropDown {
    border: none;
    border-bottom: 1px solid gray;
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
    overflow: none;
    width: 80%;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 100px;
    line-height: 150%;
    color: #000000;
  }

  .mobileFormInput {
    width: 100%;
    margin-top: 9rem;
    border: none;
    background-color: transparent;
    outline: none;
    border: none;
    box-shadow: none;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 94px;
    padding: 4rem;
    line-height: 140%;
    text-align: left;
    color: #000000;
  }

  .mobileFormInput:hover,
  .mobileFormInput:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
  }

  .mobileFormInput::placeholder {
    position: absolute;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 100px;
    line-height: 140%;
    color: #000000;
  }

  .callWithEnqueryForm {
    display: none;
  }

  .enqueryCon {
    width: 100%;
    height: 70px;
    background-color: black;
    z-index: 1000;
  }

  .inputStyle {
    width: 230px !important;
    padding: 10px;
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    color: white;
    font-size: 15px !important;
    text-align: left !important;
  }

  .border_input_style {
    border-bottom: 1px solid gray !important;
  }

  .inputStyle:hover,
  .inputStyle:focus {
    background-color: transparent !important;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  .inputStyle::placeholder {
    font-size: 15px;
    background-color: transparent !important;
  }

  .dropDownStyle {
    background-color: black;
    border: none !important;
    border-bottom: 1px solid gray !important;
    outline: none;
    color: white;
    padding: 10px;
    width: 230px !important;
  }

  .enqFormStyle {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .submitBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 18.0488px 30.3171px;
    gap: 18.05px;
    width: 152.68px;
    height: 40px;
    background: #bfbfbf;
    border-radius: 158.829px;
    cursor: pointer;
    outline: none !important;
    border: none !important;
  }

  .cotentBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .heading {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 54px;

    color: #000000;
  }

  .desc {
    text-align: center;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #727272;
    margin: 20px;
  }

  .okayBtn {
    width: 252.68px;
    height: 64px;
    background: #000000;
    border-radius: 158.829px;
    color: white;
    cursor: pointer;
    font-size: 20px;
  }
  .mobileSubmitBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    gap: 10px;
    width: 296px;
    height: 240px;
    background: #000000 !important;
    border-radius: 88px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 100px;
    line-height: 110%;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff !important;
    margin-top: 8rem;
  }
  .mobileSubmitBtn:hover {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    gap: 10px;
    width: 296px;
    height: 40px;
    background: #000000 !important;
    border-radius: 88px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 110%;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff !important;
    margin-top: 2rem;
  }
  .navigateBack {
    display: flex;
  }
  .backText {
    position: relative;
    top: 3px;
    left: 10px;
    font-size: 100px;
  }
  .roleStyle {
    display: flex;
    flex-wrap: wrap;
  }
  .roleInputDiv {
    width: 45%;
    position: relative;
    margin: 10px;
    display: flex !important;
  }
  .roleInputDiv span {
    position: relative;
    top: 5px;
    left: 2.5rem;
  }
  .roleInputStyle {
    padding: 0px !important;
    width: 8% !important;
    cursor: pointer;
    position: absolute;
  }
  .roleHeading {
    position: relative;
    right: 260px;
    margin-top: 10px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    color: #000000;
  }
  .CountryAndCity {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 1rem;
  }
  .cityErrorMsg {
    color: red;
    position: relative;
    left: 6.5rem;
    top: 0.5rem;
  }
  .roleErrorMsg {
    color: red;
    position: relative;
    left: 0.5rem;
    top: 0.5rem;
  }
  .closeIcon {
    width: 140px;
    height: 140px;
  }
} */

.enquery .selected-flag {
  /* background-color: #000000; */
}
.footerEnquiry li {
  color: #6b6b6b;
}
@media (max-width:800px){
  .enqueryCon {
    display: none;
  }
  .callWithEnqueryForm{
    width:100%;
    display: flex;
    justify-content: space-around;
  }
  .enQueryBtn {
    border-radius: 60px;
    background-color: transparent;
    width: 164px;
    height: 40px;
    left: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 110%;
    outline: none;
    border: none;
    border: 2px solid gray;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #000000;
  }
}