.promise-full-banner {
  width: 95%;
  margin: 1rem auto;
  height: auto;
}

/* .about-promise-holder > h2,
.promises-carousel-holder > h2 {
  font-family: "Jost-Medium";
  font-size: 1.625rem;
  line-height: 1.03;
  color: #17171c;
} */

/* .about-promise-holder p {
  font-family: "Jost-Light";
  font-size: 1.5rem;
  line-height: 1.65;
  color: var(--black-color);
  margin-top: 20px;
} */

.promises-carousel-holder {
  margin-top: 50px;
}

.promises-carousel-holder > h2 {
  margin-bottom: 30px;
}

.carousel-items {
  width: 300px !important;
  margin: 0 1rem;
}
/* Promise Card Styles*/
.promise-item {
  height: 250px;
  background-image: linear-gradient(to bottom, #f0f0ff, #e7e8ff);
  border-radius: 10px;
  padding: 20px 15px;
  cursor: pointer;
}

.promise-item > img {
  height: 35px;
  width: auto;
  object-fit: contain;
  margin-bottom: 20px;
}

.promise-item > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.promise-item > div > h2 {
  font-family: "Jost-Bold";
  font-size: 22px;
  font-weight: bold;
  color: #494a67;
}

.promise-item > p {
  font-family: "Jost-Regular";
  font-size: 18px;
  color: #17171c;
}

.disclaimer-text {
  margin: 2rem 0 1rem;
  text-align: center;
  font-family: "Jost-Light";
  color: var(--senary-gray-color);
}
.about-promise-holder {
 font-family: "Futura Md BT";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #727272;
  margin-top: 5%;
  margin-left: 2%;
}
@media (min-width: 1440px) {
  /* .about-promise-holder h2 {
    font-size: 28px;
  } */
  .promises-carousel-holder > h2 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 700px) {
  .desktopPromise{
    display: none;
  }
  .promises-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .promises_left_content {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    color: #000000;
  }

  .promises_left_style {
    display: flex;
    align-items: center;
  }

  .promises_card_wrapper {
    margin-top: 5%;
    width: 328px;
    height: auto;
  }
  .promises_card_wrapper_small {
    height: 128px;
  }
  .promises_small_background {
    position: absolute;
    object-fit: cover;
  }

  .ic_security_promises {
    position: absolute;
    margin-left: 8.5%;
    margin-top: 8.25%;
    width: 64px;
    height: 64px;
    opacity: 0.2;
    object-fit: contain;
  }
  .promises_name {
    position: absolute;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #bb994f;
    margin-left: 13%;
    margin-top: 14%;
  }
  .name_icon {
    display: flex;
  }
  .forward_icon {
    width: 16px;
    height: 16px;
    position: absolute;
    margin-left: 73%;
    margin-top: 14%;
    color: #6d6e71;
  }
  .upward_icon {
    width: 24px;
    height: 24px;
    position: absolute;
    margin-left: 70%;
    margin-top: 13%;
    color: #6d6e71;
  }
  .promises_card_wrapper_full {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .promises_end {
    margin-top: 7%;
   font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    color: #6d6e71;
    margin-left: 2%;
    margin-bottom: -10%;
  }
  .promises_end_link {
    color: #000000;
    text-decoration: none;
  }
  .showOnClick {
    min-height: 20rem;
    width: 328px;
  }

  .promises_full_card {
    position: absolute;
    object-fit: fill;
    margin-bottom: 4%;
    width: 328px;
    height: 20rem;
  }

  .showOnClick_Des {
    position: absolute;
   font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #ffffff;
    padding: 5%;
    width: 84%;
    margin-bottom: 3%;
    height: min-content;
  }
  .showOnClick_title {
    position: absolute;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 24.6346px;
    line-height: 33px;
    text-align: center;
    letter-spacing: 2.73718px;
    text-transform: uppercase;
    color: #bb994f;
  }
  .show_on_click_div {
    height: 128px;
  }
}

@media only screen and (min-width: 700px) {
  .boxStyle{
    display: none !important;
  }
}

@media only screen and (max-width: 700px) {
  .forDesktop {
    display: none;
  }

  .forMobile {
    position: relative;
    bottom: 20px;
  }

  .container .title {
    width: 1000%;
    height: 24px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #ffffff;
    margin-top: 20px;
  }

  .container {
    padding: 0;
    margin: 0;
    width: 100%;
    min-height: 800px;
    display: flex;
    flex-direction: column;
    background: rgba(24, 36, 40, 1);
    justify-content: center;
    align-items: center;
  }

  .galley-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 70vh;
    align-items: center;
  }

  .item {
    flex: 4 1 0;
    width: 320px;
    max-height: 130px !important;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    transition: all 1s ease;
    background-image: var(--menubackicon);
    background-color: transparent;
    margin-top: 20px;

    overflow: hidden;
  }

  .desc {
    display: flex;
    align-items: flex-end;
    display: none;
    position: relative;
    bottom: 50px;
    transition: all 2s ease;
    width: 80%;
    text-align: center;
  }

  .desc p {
    width: 80%;
    text-align: center;
    position: relative;
    left: 30px;
  }

  .menuText {
    color: rgba(187, 153, 79, 1);
    font-size: 25px;
    transition: all 1s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    bottom: 30px;
    height: 130px;
  }

  .menuText .text {
    margin-top: 50px;
  }

  .menuText .text img {
    width: 60px !important;
    margin-left: 28px;
    transition: all 1s ease;
  }

  .menuText .text p {
    margin-top: -70px;
    font-size: 24px;
    text-transform: uppercase;
    transition: all 1s ease;
  }

  .arrow img {
    position: relative;
    top: 25px;
    transition: all 1s ease;
  }

  .item:hover .galley-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 700vh;
    align-items: center;
  }

  .item:hover {
    flex: 4 1 0;
    min-height: 148px !important;
    box-sizing: border-box;
    overflow: hidden !important;

    width: 325px !important;
  }

  .item:hover .desc {
    display: inline;
    transform: translateY(-100px);
    color: white;
  }

  .item:hover .menuText .text p {
    transform: translateY(20px);
    font-size: 12px;
    position: relative;
    width: 100%;

    top: 10px;
  }

  .item:hover .menuText .text img {
    transform: translateY(-25px);
    width: 30px !important;
    position: relative;
    right: 10px;
  }

  .item:hover .menuText .arrow img {
    transform: translateY(180px) rotate(-180deg);
  }

  .item:hover .arrow img {
    top: -90px;
    font-size: 15px !important;
    width: 20px;
  }

  .item:hover .desc p {
    margin-top: -25px;
    font-size: 14px;
  }

  .item:hover .menuText .text {
    margin-top: 20px;
  }

  .menuPageCon {
    /* min-height: 100vh; */
  }

  .logoWithHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    bottom: 20px;
  }

  .logoWithHeader img {
    width: 80px;
    margin-top: 50px;
  }

  .logoWithHeader .title {
    height: 24px;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #ffffff;
    margin-top: 40px;
    font-family: "Futura Md BT";
  }

  .wrapper {
    width: 98%;
    padding: 10px;
    margin: 10px auto;
  }

  .beforeAfter {
    max-width: 420px;
    min-height: 170px;
    background: #182428;
    position: relative;
    margin: 0 auto;
    transition: all 2s ease;
    padding: 5px;
    margin-top: 10px;
  }

  .beforeAfter:before {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    border-radius: 0 0 100% 0;
    transition: all 2s ease;
    scale: 1.1;
  }

  .beforeAfter::after {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    right: 0;
    background: #fff;
    border-radius: 0 0 0 100%;
    transition: all 2s ease;
    scale: 1.1;
  }

  .inside :before {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #fff;
    border-radius: 0 100% 0 0;
    transition: all 2s ease;
    scale: 1.1;
  }

  .inside :after {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: 0;
    right: 0;
    background: #fff;
    border-radius: 100% 0 0 0;
    transition: all 2s ease;
    scale: 1.1;
  }

  .beforeAfter .menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 2s ease;
  }

  .shieldWithTitle {
    margin-top: 0px;
    transition: all 1s ease;
    margin-left: 0px;
    transition: all 2s ease;
    text-align: center;
  }

  .shieldWithTitle img {
    width: 50px;
    text-align: center;
    transition: all 1s ease;
    transition: all 2s ease;
  }

  .shieldWithTitle p {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: rgba(187, 153, 79, 1);
    text-align: center;
    letter-spacing: 4px;
    text-transform: uppercase;
    transition: all 1s ease;
    margin-top: -50px;
    font-style: normal;
    font-weight: 400;
    font-family: "Futura Md BT";
    line-height: 24px;
    margin-top: 10px;
    text-align: center;
    letter-spacing: 4px;
    text-transform: uppercase;
  }

  .arrowWrapper {
    margin-top: 0px;
    margin-right: -2px;
    transition: all 2s ease;
  }

  .arrowWrapper img {
    margin-top: 35px;
    transition: all 1s ease;
    line-height: 0;
  }
/* .arrowWrapper img .arrow3{
  margin-left: -10px;
} */
  .beforeAfter .descp {
    display: none;
    transition: all 2s ease;
  }

  /* menu open */
  .openMenu {
    max-width: 420px;
    min-height: 150px;
    background: #182428;
    position: relative;
    margin: 0 auto;
    transition: all 2s ease;
    padding: 5px;
    margin-top: 20px;
  }

  .openMenu:before {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    border-radius: 0 0 100% 0;
    transition: all 2s ease;
    scale: 1.1;
  }

  .openMenu::after {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    right: 0;
    background: #fff;
    border-radius: 0 0 0 100%;
    transition: all 2s ease;
    scale: 1.1;
  }

  .openMenu .inside :before {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #fff;
    border-radius: 0 100% 0 0;
    transition: all 2s ease;
    scale: 1.1;
  }

  .openMenu .inside :after {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: 0;
    right: 0;
    background: #fff;
    border-radius: 100% 0 0 0;
    transition: all 2s ease;
    scale: 1.1;
  }

  .openMenu .menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 2s ease;
  }

  .openMenu .inside .menu .shieldWithTitle {
    margin-top: 0px;
    transition: all 2s ease;
  }

  .openMenu .inside .menu .shieldWithTitle img {
    width: 40px;
    position: relative;
    top: 10px;
    right: 0;
    transition: all 2s ease;
  }

  .openMenu .inside .menu .shieldWithTitle p {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(187, 153, 79, 1);
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-top: 10px;
    transition: all 2s ease;
  }

  .openMenu .arrowWrapper img {
    transform: translateY(00px) rotate(180deg);
    transition: all 2s ease;
  }

  .openMenu .descp {
    color: white;
    margin-top: 30px;
    text-align: center;
    width: 90%;
    transition: all 2s ease;
  }

  .adjust {
    color: red !important;
  }

  .shieldWithTitle #shield2 {
    position: relative !important;
    left: 100px;
  }

  /* New style for menu */
  .menuCon {
    height: 900px;
    background: rgba(24, 36, 40, 1);
    padding-top: 50px;
  }

  .menuItem {
    width: 320px;
    min-height: 170px;
    transition: all 2s ease;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 1s ease;
    background-image: var(--menubackicon);
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .menuItem .iconWithHeader {
    margin-top: 20px;
    transition: all 2s ease;
  }

  .menuItem .iconWithHeader img {
    width: 50px;
    margin-left: 28px;
    transition: all 2s ease;
  }

  .menuItem .arrowIcon {
    position: relative;
    top: 20px;
    right: 5px;
    transition: all 1s ease;
  }

  .menuItem .trans {
    position: relative;
    left: 45px;
  }

  .menuItem .iconWithHeader p {
    color: rgba(187, 153, 79, 1);
    font-size: 24px;
    text-transform: uppercase;
    transition: all 2s ease;
  }

  .description {
    display: none;
    transition: all 2s ease;
  }

  .description p {
    text-align: center;
    transition: all 2s ease;
  }

  .collapseMenu {
    height: 180px;
    width: 320px;
    transition: all 2s ease;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    transition: all 1s ease;
    background-image: var(--menubackicon);
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
  }

  .collapseMenu .iconWithHeader {
    position: relative;

    top: 20px;
  }

  .collapseMenu .iconWithHeader p {
    color: rgba(187, 153, 79, 1);
    font-size: 16px;
    text-transform: uppercase;
  }

  .collapseMenu .iconWithHeader img {
    width: 25px;
    margin-left: 20px;
  }

  .collapseMenu .description {
    display: inline-block;
    margin-top: 20px;
  }

  .collapseMenu .arrowIcon {
    transform: translateY(150px) rotate(-180deg);
    display: flex;
    justify-content: center;
  }

  .con {
    min-height: 800px;
    background: rgba(24, 36, 40, 1);
  }

  .outer {
    overflow: hidden;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .inner {
    border: 1px solid #888;
    height: 150px;
    margin-top: 20px;
    width: 90%;
    background: rgba(255, 255, 255, 0.05) !important;
  }

  .inner i {
    width: 40px;
    height: 40px;
    border: 1px solid #888;
    border-radius: 50%;
    background-color: #fff;
  }

  .inner .top {
    margin-top: -20px;
  }

  .inner .bottom {
    margin-top: -20px;
    margin-bottom: -22px;
  }

  .inner .left {
    float: left;
    margin-left: -20px;
  }

  .inner .right {
    float: right;
    margin-right: -20px;
  }

  .content {
    min-height: 80px;
  }
}